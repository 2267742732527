/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext } from "react"
import LayoutDashBoard from "../../components/Layout/Dashboard"
import ReportingFilter from "../../components/Reporting/Filter"
import DataContext from "../../context/Data/DataContext"
import ReportingContext from "../../context/Reporting/ReportingContext"
import PageSubtitle from "../../components/PageSubtitle"
const ReportingContainer = props => {
  const { currentUserCompaniesAsOp } = useContext(DataContext).data
  const {
    dateRange,
    setDateRange,
    company,
    setCompany,
    companies,
    brands,
    brand,
    setBrand,
  } = useContext(ReportingContext)
  // console.log({ brand, company })
  return (
    <>
      <LayoutDashBoard report metaTitle="Reporting">
        <div className="flex mb-8 pb-6">
          <div className="lg:w-1/2 lg:pr-8">
            <PageSubtitle title="Reporting to help you uncover, track and analyse your data across products, assets, customers, downloads, orders and inventory." />
          </div>
          <div className="lg:w-1/2 lg:pl-8 lg:ml-auto">
            {currentUserCompaniesAsOp && (
              <ReportingFilter
                currentUserCompaniesAsOp={currentUserCompaniesAsOp}
                setCompany={setCompany}
                company={company}
                brands={brands}
                brand={brand}
                setBrand={setBrand}
                companies={companies}
                setDateRange={setDateRange}
                dateRange={dateRange}
              />
            )}
          </div>
        </div>
        {props.children}
      </LayoutDashBoard>
    </>
  )
}
export default ReportingContainer
