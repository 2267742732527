/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import SectionCard from "../../Cards/Dashboard/Reporting/SectionCard"
import { Table, Tr, Td } from "../../Table/TableElements"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import WidgetHead from "./WidgetHead"
import ImageCardSmall from "../../Elements/ImageCardSmall"
import Modal from "../../Modal/Modal"
import Button from "../../Elements/Button"
import moment from "moment"
import { navigate } from "gatsby"
import Skeleton from "../../Skeleton"
const RecentOrdersWidget = props => {
  const { reports, getReport, loading, company, brand, dateRangeFetch } =
    useContext(ReportingContext)
  const [data, setData] = useState(null)
  const [current, setCurrent] = useState(null)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (!loading) getReport("recent_orders").then(res => setData(res))
  }, [loading])
  useEffect(() => {
    if (!loading && reports["recent_orders"] !== null)
      getReport("recent_orders").then(res => setData(res))
  }, [dateRangeFetch])
  useEffect(() => {
    if (!loading && reports["recent_orders"] !== null)
      getReport("recent_orders", company.value, brand?.value || null).then(res => setData(res))
  }, [company, brand])
  const openModal = item => {
    setCurrent(item)
    setOpen(true)
  }
  const SmallRow = props => {
    return (
      <Tr>
        <Td className="!font-semibold !text-[#222]">{props.label}</Td>
        <Td>{props.value}</Td>
      </Tr>
    )
  }

  return (
    <>
      <WidgetHead
        title={"Recent Orders"}
        body={"Users who have ordered from your brands most recently."}
      />
      <div className="border p-[0] border-white rounded-[8px] mb-[15px] bg-white">
        {data !== null ? (
          <div>
            {data.map((item, k) => {
              let timeAgo = moment(new Date(item.date)).fromNow()
              return (
                <div
                  // onClick={() => openModal(item)}
                  key={k}
                  className={`rounded-[6px] bg-white ${k < data.length - 1 ? `mb-[15px]` : ``
                    } px-5 py-4 border shadow-[0_10px_30px_rgba(0,0,0,.04)] hover-group`}
                >
                  <div>
                    <div className="flex items-start space-x-2">
                      <ImageCardSmall
                        image={item.assets[0].asset_images[0]}
                        size={40}
                      />
                      <div className="flex flex-col">
                        <span className="text-med-16 !text-[14px] ">
                          <span className="red !leading-[1.3]">
                            {item.assets[0].asset_title}
                          </span>
                        </span>

                        <div className="text-reg-14 !text-[13px] !leading-[1.3] mt-1">
                          {item.assets.length > 1 && (
                            <span className="text-[#222] mr-auto text-[13px] cursor-pointer">
                              + {item.assets.length - 1} more of your assets.
                            </span>
                          )}{" "}
                          {timeAgo} by {item.user} from {item.company[0]}.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <Skeleton table />
        )}
        {/* <Modal
          isOpen={open}
          closeModal={() => setOpen(!open)}
          title={"Recent Order Details"}
        >
          <Table flush>
            <SmallRow label="Order Date" value={current?.date} />
            <SmallRow label="User" value={current?.user || "N/A"} />
            <SmallRow label="Company" value={current?.company[0] || "N/A"} />
          </Table>

          <Table flush flushHead columns={["Items in this order:", "", ""]}>
            {current?.assets.map((i, k) => {
              return (
                <Tr key={k}>
                  <Td className="flex items-center space-x-2">
                    <ImageCardSmall image={i.asset_images[0]} size={50} />
                    <span className="text-reg-17">{i.asset_title}</span>
                  </Td>
                  <Td>{i.asset_id}</Td>
                  <Td>
                    <Button
                      widthAuto
                      onClick={() => {
                        navigate(`/dashboard/my-assets`, { state: { ...i } })
                      }}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Table>
        </Modal> */}
        {/*data !== null && (
          <Table columns={["User", "Company", "Assets"]} xs flush>
            {data.map((item, k) => {
              return (
                <Tr key={k}>
                  <Td>{item.user}</Td>
                  <Td>Company</Td>
                  <Td>
                    {item.assets.length} assets,{" "}
                    {item.assets.map(i => i.asset_title + ", ")}
                  </Td>
                </Tr>
              )
            })}
          </Table>
        )*/}
      </div>
    </>
  )
}

export default RecentOrdersWidget
