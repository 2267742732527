/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
import FloatingDateRange from "../../components/Elements/Form/FloatingDateRange"
import infoIcon from "../../assets/icons/info.svg"
import DataContext from "../../context/Data/DataContext"
import StateContext from "../../context/state/StateContext"
import { myEntitySuggestions } from '../../helpers/DrupalHelper'
import CircleAvatar from "../Elements/CircleAvatar"
const ReportingFilter = props => {
  const {
    company,
    setCompany,
    companies,
    setDateRange,
    dateRange,
    brands,
    brand,
    setBrand,
    myBrandsAsOptions,
  } = props
  // console.log(myBrandsAsOptions, "myBrandsAsOptions")

  const { state } = useContext(StateContext)
  const [options, setOptions] = useState([])
  const [brandOptions, setBrandOptions] = useState([])

  useEffect(() => {
    if (company) {
      const filtered = options.filter(v => parseInt(v.company) === parseInt(company.value))
      setBrandOptions(filtered)
    }
  }, [options, company])

  // useEffect(() => {
  //   if (company && options.length) {
  //     console.log('asdasdasdadsdsa')
  //     console.log(options, "options")
  //     console.log(company.value, 'company.value')
  //     const filtered = options.filter(v => parseInt(v.company) === parseInt(company.value))
  //     console.log(filtered, "filtered")
  //     setBrandOptions(filtered)
  //   }
  // }, [company])
  useEffect(async () => {
    if (options.length === 0) {
      const data = await myEntitySuggestions(state.token, "brand", ["default"])
      // console.log(data)

      setOptions(
        data.map(v => ({
          ...v,
          value: v.target_id ? v.target_id : v.id,
          label: v.title,
          company: v.company,
          renderLabel: (
            <div className="flex items-center">
              <CircleAvatar border xs image={v.thumb} />
              <span className="ml-2">{v.title}</span>
            </div>
          ),
        }))
      )
    }
  }, [])

  return (
    <div className="border rounded-xl	p-4">
      <p class="mb-4 text-reg-14 !leading-[1.5]">
        Company, Brand and Date Range selected will filter data across all
        charts and all reports.
        <br /> * Fields filtered by Brands and Date Range are marked by an
        asterisk where applicable.
      </p>
      <div className="bg-white md:grid md:grid-cols-3 md:gap-3">
        <FloatingSelect
          options={companies}
          value={company}
          label={"Choose company"}
          onChange={v => setCompany(v)}
          className="!mb-0"
        />
        <div className="flex flex-col md:flex-row md:items-center md:justify-end space-y-[15px] md:space-y-0 md:space-x-[15px] w-full">
          <div className="md:grow md:!mr-auto">
            <FloatingDateRange
              name="range"
              label="Date Range"
              dateRange={dateRange}
              setDateRange={setDateRange}
              className="!mb-0"
            />
          </div>
        </div>

        <FloatingSelect
          options={brandOptions}
          value={brand}
          label={"Choose brand"}
          // type="myBrandsAsOptions"
          onChange={v => {
            // console.log(v, "vvvvvvvv")
            setBrand(v)
          }}
          className="!mb-0"
        />
      </div>
    </div>
  )
}

export default ReportingFilter
