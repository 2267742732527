/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import SectionCard from "../../Cards/Dashboard/Reporting/SectionCard"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import { palette, paletteStrong } from "../../Styles"
import Navigate from "../../../assets/icons/navigate.svg"
import Download from "../../../assets/icons/download-alt.svg"
import Warehouse from "../../../assets/icons/warehouse-alt.svg"
import UserIcon from "../../../assets/icons/user.svg"
import Settings from "../../../assets/icons/settings.svg"
import Sent from "../../../assets/icons/sent.svg"
import Shop from "../../../assets/icons/shop.svg"
import Rss from "../../../assets/icons/rss.svg"
import Folder from "../../../assets/icons/folder.svg"
import Coupon from "../../../assets/icons/coupon-alt.svg"
import Pin from "../../../assets/icons/pin.svg"
import Grid from "../../../assets/icons/grid.svg"
import Cart from "../../../assets/icons/cart.svg"
import CountUp from "react-countup"
import { Link } from "gatsby"
import Skeleton from "../../Skeleton"
import DataContext from "../../../context/Data/DataContext"
const countUpDuration = 0.5

const HighlightsWidget = props => {
  const { loading, reports, getReport, company, dateRangeFetch } =
    useContext(ReportingContext)
  const { currentUserCompaniesAsOp } = useContext(DataContext).data

  const [data, setData] = useState(null)
  useEffect(() => {
    if (!loading) getReport("highlights").then(res => setData(res))
  }, [loading])
  useEffect(() => {
    if (!loading && reports["highlights"] !== null)
      getReport("highlights").then(res => setData(res))
  }, [dateRangeFetch])
  useEffect(() => {
    if (!loading && reports["highlights"] !== null)
      getReport("highlights", company.value).then(res => setData(res))
  }, [company])
  // console.log(data)
  return (
    <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white">
      {data === null && (
        <div className={`grid lg:grid-cols-4 gap-4`}>
          {Array(4)
            .fill(1)
            .map((v, i) => (
              <div key={i}>
                <Skeleton height={60} rounded={"4px"} className="mr-2" />
              </div>
            ))}
        </div>
      )}
      {data !== null && (
        <>
          <div className={`grid lg:grid-cols-4 gap-4`}>
            {Object.keys(data)
              .splice(0, 4)
              .map((item, k) => {
                let title = ""

                if (item === "most_order_asset")
                  title = "was your asset ordered most often"
                if (item === "number_of_assets_created_by_company")
                  title = "new assets were added for your brand"
                if (item === "number_of_assets_with_low_inventory")
                  title = "assets are running low on inventory"
                if (item === "number_of_retailer_ordered_first_time")
                  title = "retailers ordered your assets for the first time"

                let linkTo = "#"
                if (item === "most_order_asset") linkTo = "#"
                if (item === "number_of_assets_created_by_company") linkTo = "#"
                if (item === "number_of_assets_with_low_inventory") linkTo = "#"
                if (item === "number_of_retailer_ordered_first_time")
                  linkTo = "#"
                // console.log(item)

                if ([1, 2, 3, 4].includes(k)) {
                  return (
                    <Link
                      onClick={() => {
                        if (item === "number_of_retailer_ordered_first_time") {
                          props.setSelectedTab(4)
                        }
                        if (item === "number_of_assets_with_low_inventory") {
                          props.setInventoryStatus({
                            value: "low_stock",
                            label: "Low Stock",
                          })
                          props.setSelectedTab(7)
                        }
                        if (item === "number_of_assets_created_by_company") {
                          props.setSelectedTab(2)
                        }
                      }}
                      to={linkTo}
                      className={`rounded py-3 px-4 text-white !text-left flex items-center  transition-all`}
                      css={css`
                        background-color: ${paletteStrong[k]};
                        &:hover {
                          box-shadow: 0 0 0 2px #fff,
                            0 0 0 3px ${paletteStrong[k]};
                        }
                      `}
                    >
                      <div className="flex items-center gap-3">
                        <h1 className="text-strong-45 !leading-1 !text-left pr-2 pl-3 !font-[600]">
                          <CountUp
                            duration={countUpDuration}
                            startVal={0}
                            end={data[item]}
                          />
                        </h1>

                        <h2 className="text-reg-20 !text-white !leading-[1.3] !text-[14px] !text-left">
                          {title}
                        </h2>
                      </div>
                    </Link>
                  )
                }
                return (
                  <Link
                    to={linkTo}
                    onClick={() => {
                      if (item === "most_order_asset") {
                        props.setTopAsset(data[item])
                        props.setSelectedTab(6)
                      }
                      if (item === "number_of_retailer_ordered_first_time") {
                        props.setSelectedTab(4)
                      }
                    }}
                    className={`rounded py-3 px-4 text-white !text-left flex items-center transition-all`}
                    css={css`
                      background-color: ${paletteStrong[k]};
                      &:hover {
                        box-shadow: 0 0 0 2px #fff,
                          0 0 0 3px ${paletteStrong[k]};
                      }
                    `}
                  >
                    <h1 className="text-strong-20 !leading-[1.3] !text-[12px] !text-left mb-0">
                      {data[item]}
                    </h1>
                    <h2 className="text-reg-20 !text-white !leading-[1.3] !text-[14px] !text-left">
                      {title}
                    </h2>
                  </Link>
                )
              })}
          </div>
          <div className="grid lg:grid-cols-7 gap-4">
            {Object.keys(data)
              .splice(4, 7)
              .map((item, k) => {
                let title = ""
                if (item === "brand_visits") title = "brand visits"
                if (item === "asset_visits") title = "asset visits"
                if (item === "downloads") title = "downloads"
                if (item === "orders") title = "orders"
                if (item === "messages") title = "messages"
                if (item === "product_visits") title = "product visits"
                if (item === "spend") title = "spend"

                return (
                  <div className="border border-[#EBEBEB] rounded-[4px] py-4 px-2 text-center mt-4 !text-[#717171] !leading-[1.1] flex items-center justify-center">
                    <span className="!text-[#717171] text-strong-25 !leading-[1] pr-1">
                      {item === "spend" ? "$" + data[item] : data[item]}
                    </span>{" "}
                    {title}
                  </div>
                )
              })}
          </div>
        </>
      )}
    </div>
  )
}

export default HighlightsWidget
