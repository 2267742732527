import React, { useState, useEffect, useContext } from "react"
import ReportingInventorySummaryContext from "./ReportingInventorySummaryContext"
// import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  // getReportMyHighlights,
  // getReportTopPerformers,
  getReportInventorySummary,
  // getReportRecentOrders,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../state/StateContext"
// import { list } from "postcss"
import DataContext from "../../Data/DataContext"
import ReportingContext from "../ReportingContext"
// import ModalCart from "../../../components/Cart/ModalCart"
// import AuthenticationContext from "../../authentication/AuthenticationContext"
import NotificationContext from "../../Notification/NotificationContext"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import AsyncFloatingSelect from "../../../components/Elements/Form/AsyncFloatingSelect"
import FloatingRangeSlider from "../../../components/Elements/Form/FloatingRangeSlider"
function getFormattedDateForFetch(date) {
  var year = date.getFullYear()

  var month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : "0" + month

  var day = date.getDate().toString()
  day = day.length > 1 ? day : "0" + day

  return day + "/" + month + "/" + year
}
export const ReportingInventorySummaryProvider = props => {
  const { company, brand, companyUsers, dateRange, segments } =
    useContext(ReportingContext)
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = state
  const {
    // regionsAsOptions,
    myBrandsAsOptions,
    categoriesAsOptions,
    tagsAsOptions,
    // myUsersAsOptions,
  } = useContext(DataContext).data

  const initData = {
    1: [],
  }
  const [body, setBody] = useState({})
  const [page, setPage] = useState(1)
  const [allData, setAllData] = useState([])
  const [data, setData] = useState(initData)

  useEffect(() => {
    companyUsers.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user: { ...prevData.user, options: companyUsers },
        }
      })
  }, [companyUsers])

  // useEffect(() => {
  //   categoriesAsOptions.length &&
  //     setParams(prevData => {
  //       return {
  //         ...prevData,
  //         categories: {
  //           ...prevData.categories,
  //           options: categoriesAsOptions,
  //         },
  //       }
  //     })
  // }, [categoriesAsOptions])
  useEffect(() => {
    myBrandsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          brands: { ...prevData.brands, options: myBrandsAsOptions },
        }
      })
  }, [myBrandsAsOptions])

  useEffect(() => {
    tagsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          tags: { ...prevData.tags, options: tagsAsOptions },
        }
      })
  }, [tagsAsOptions])

  useEffect(() => {
    segments.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user_segments: {
            ...prevData.user_segments,
            options: segments,
          },
        }
      })
  }, [segments])

  const [params, setParams] = useState({
    stock: {
      value: [],
      component: FloatingSelect,
      label: "Stock Status",
      options: [
        { value: "in_stock", label: "In Stock" },
        { value: "out_of_stock", label: "Out of Stock" },
        { value: "low_stock", label: "Low Stock" },
      ],
      props: { isMulti: true },
    },
    fulfillment: {
      value: [],
      component: FloatingSelect,
      label: "Fulfillment by",
      options: [
        { value: "o2o", label: "O2O Warehouse" },
        { value: "in-house", label: "In house" },
        { value: "third-party", label: "Third party" },
      ],
      props: { isMulti: true },
    },

    assets: {
      value: [],
      component: AsyncFloatingSelect,
      // props: {
      //
      // },
      label: "Assets",
      options: [],
      props: {
        isMulti: true,
        myEntitySuggest: true,
        body: { entity: "asset", bundle: ["default"] },
        provisionTempState: true,
        wrapMultiItems: true,
      },
    },
    tags: {
      value: [],
      component: FloatingSelect,
      label: "Asset Tags",
      options: [],
      props: { isMulti: true },
    },
    user_segments: {
      value: [],
      component: FloatingSelect,
      label: "Allocated User Segments",
      options: segments,
      props: {
        isMulti: true,
      },
    },
    retail_qty: {
      type: "range",
      value: 0,
      component: FloatingRangeSlider,
      label: "Retail Quantity",
    },
    internal_qty: {
      type: "range",
      value: 0,
      component: FloatingRangeSlider,
      label: "Internal Quantity",
    },
    unallocated_qty: {
      type: "range",
      value: 0,
      component: FloatingRangeSlider,
      label: "Unallocated Quantity",
    },
  })
  // console.log(params)
  useEffect(() => {
    let bodyVars = {}
    Object.keys(params).map((item, k) => {
      if (Array.isArray(params[item].value) && params[item].value.length > 0) {
        bodyVars[item] = params[item].value
      } else if (!Array.isArray(params[item].value) && params[item].value) {
        bodyVars[item] = params[item].value
      }

      setBody(bodyVars)
    })

    return bodyVars
  }, [params])

  const setParam = (param, value) => {
    setParams(prevData => {
      return {
        ...prevData,
        [param]: { ...prevData[param], value: value },
      }
    })
    return { ...params, [param]: { ...params[param], value: value } }
  }

  const getAllData = async () => {
    let response = null
    try {
      response = await getReportInventorySummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value||null,
        args: body,
        date_range: dateRange
          ? `${getFormattedDateForFetch(
              dateRange[0].startDate
            )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
          : "",
        download: true,
      })
      setAllData(response)
      return response
    } catch (e) {
      return toggleNotification({
        content: "Failed to generate CSV (Inventory Summary)",
        error: true,
      })
    }
  }
  const getData = async (pageNumber = 1, refresh = false) => {
    // if(download === true) {
    //   let response = null
    //   try {
    //     response = await getReportInventorySummary({
    //       token: token,
    //       companyId: company.value,
    //       args: body,
    //       date_range: dateRange
    //         ? `${getFormattedDateForFetch(
    //             dateRange[0].startDate
    //           )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
    //         : "",
    //       // offset: pageNumber == 1 ? "0" : `${(pageNumber - 1) * 50}`,
    //       download: true,
    //     })
    //     setAllData(response)
    //     return response;
    //   } catch (e) {
    //     return toggleNotification({ content: "error", error: true })
    //   }
    // }

    setPage(pageNumber)

    if (data[pageNumber]?.length > 0 && refresh === false)
      return data[pageNumber]

    let response = null
    try {
      response = await getReportInventorySummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value||null,
        args: body,
        date_range: dateRange
          ? `${getFormattedDateForFetch(
              dateRange[0].startDate
            )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
          : "",
        offset: pageNumber == 1 ? "0" : `${(pageNumber - 1) * 50}`,
      })
    } catch (e) {
      return toggleNotification({ content: "error", error: true })
    }

    setData(prevData => {
      return {
        ...prevData,
        [pageNumber]: response,
      }
    })
    return response
  }

  useEffect(() => {
    // if (data !== initData && allData.length === 0) {
      if (data !== initData) {
        getAllData()
      }
    // }
  }, [data])
  useEffect(() => {
    if (allData.length !== 0) getAllData()
  }, [company, brand])

  return (
    <ReportingInventorySummaryContext.Provider
      value={{
        page,
        setPage,
        setBody,
        data,
        setData,
        getData,
        allData,
        setParam,
        params,
        body,
      }}
    >
      {props.children}
    </ReportingInventorySummaryContext.Provider>
  )
}

export default ReportingInventorySummaryProvider
