import React, { useState, useEffect, useContext } from "react"
import ReportingAssetSummaryContext from "./ReportingAssetSummaryContext"
import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  getReportMyHighlights,
  getReportTopPerformers,
  getReportAssetSummary,
  getReportRecentOrders,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../state/StateContext"
import { list } from "postcss"
import DataContext from "../../Data/DataContext"
import ReportingContext from "../ReportingContext"
import ModalCart from "../../../components/Cart/ModalCart"
import AuthenticationContext from "../../authentication/AuthenticationContext"
import NotificationContext from "../../Notification/NotificationContext"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import AsyncFloatingSelect from "../../../components/Elements/Form/AsyncFloatingSelect"
function getFormattedDateForFetch(date) {
  var year = date.getFullYear()

  var month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : "0" + month

  var day = date.getDate().toString()
  day = day.length > 1 ? day : "0" + day

  return day + "/" + month + "/" + year
}
export const ReportingAssetSummaryProvider = props => {
  const {
    company,
    companyUsers,
    dateRange,
    deliveryLocations,
    brand,
    segments,
  } = useContext(ReportingContext)
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = state
  const {
    regionsAsOptions,
    myBrandsAsOptions,
    categoriesAsOptions,
    tagsAsOptions,
    myUsersAsOptions,
  } = useContext(DataContext).data
  const initData = {
    1: [],
  }

  const [body, setBody] = useState({})
  const [page, setPage] = useState(1)
  const [allData, setAllData] = useState([])
  const [data, setData] = useState(initData)
  useEffect(() => {
    companyUsers.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user: { ...prevData.user, options: companyUsers },
        }
      })
  }, [companyUsers])
  // useEffect(() => {
  //   regionsAsOptions.length &&
  //     setParams(prevData => {
  //       return {
  //         ...prevData,
  //         regions: { ...prevData.regions, options: regionsAsOptions },
  //       }
  //     })
  // }, [regionsAsOptions])
  useEffect(() => {
    myBrandsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          brands: { ...prevData.brands, options: myBrandsAsOptions },
        }
      })
  }, [myBrandsAsOptions])
  useEffect(() => {
    categoriesAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          categories: {
            ...prevData.categories,
            options: categoriesAsOptions,
          },
        }
      })
  }, [categoriesAsOptions])
  useEffect(() => {
    tagsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          tags: { ...prevData.tags, options: tagsAsOptions },
        }
      })
  }, [tagsAsOptions])
  useEffect(() => {
    deliveryLocations.length &&
      setParams(prevData => {
        return {
          ...prevData,
          delivery_location: {
            ...prevData.delivery_location,
            options: deliveryLocations,
          },
        }
      })
  }, [deliveryLocations])
  useEffect(() => {
    segments.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user_segments: {
            ...prevData.user_segments,
            options: segments,
          },
        }
      })
  }, [segments])
  const [params, setParams] = useState({
    active: {
      value: [],
      component: FloatingSelect,
      label: "Active",
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ],
      props: { isMulti: true },
    },
    asset_type: {
      value: [],
      component: FloatingSelect,
      label: "Asset Type",
      options: [
        { value: "digital", label: "Digital" },
        { value: "physical", label: "Physical" },
      ],
      props: { isMulti: true },
    },
    // regions: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Regions",
    //   options: [],
    //   props: { isMulti: true },
    // },
    assets: {
      value: [],
      component: AsyncFloatingSelect,
      // props: {
      //
      // },
      label: "Assets",
      options: [],
      props: {
        isMulti: true,
        myEntitySuggest: true,
        body: { entity: "asset", bundle: ["default"] },
        provisionTempState: true,
        wrapMultiItems: true,
      },
    },
    categories: {
      value: [],
      component: FloatingSelect,
      label: "Asset Categories",
      props: { isMulti: true },
      options: [],
    },
    tags: {
      value: [],
      component: FloatingSelect,
      label: "Asset Tags",
      options: [],
      props: { isMulti: true },
    },
    products: {
      value: [],
      component: AsyncFloatingSelect,
      label: "Associated Products",
      options: [],
      props: {
        isMulti: true,
        myEntitySuggest: true,
        body: { entity: "product", bundle: ["default"] },
        provisionTempState: true,
        wrapMultiItems: true,
      },
    },
    // brands: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Brands",
    //   options: [],
    //   props: { isMulti: true },
    // },

    user_segments: {
      value: [],
      component: FloatingSelect,
      label: "User Segments",
      options: segments,
      props: {
        isMulti: true,
      },
    },
    // retailers: {
    //   value: [],
    //   component: AsyncFloatingSelect,
    //   label: "Retailers",
    //   props: {
    //     body: { entity: "retailer", bundle: ["default"] },
    //     isMulti: true,
    //   },
    //   options: [],
    // },
    // delivery_location: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Delivery location",
    //   options: [],
    //   props: { subtitleSelector: "location" },
    // },
    // user: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "User",
    //   props: { isMulti: true },
    //   options: [],
    // },
    // retailer_in_stock: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Retailer In Stock",
    //   props: { isMulti: false },
    //   options: ["N/A", "Yes", "No"],
    // },
    // rep_in_stock: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Sales Rep In Stock",
    //   props: { isMulti: false },
    //   options: ["N/A", "Yes", "No"],
    // },
  })

  useEffect(() => {
    let bodyVars = {}
    Object.keys(params).map((item, k) => {
      if (Array.isArray(params[item].value) && params[item].value.length > 0)
        bodyVars[item] = params[item].value
      else if (!Array.isArray(params[item].value) && params[item].value)
        bodyVars[item] = params[item].value

      setBody(bodyVars)
    })

    return bodyVars
  }, [params])

  const setParam = (param, value) => {
    setParams(prevData => {
      return {
        ...prevData,
        [param]: { ...prevData[param], value: value },
      }
    })
    return { ...params, [param]: { ...params[param], value: value } }
  }

  const getAllData = async () => {
    let response = null
    try {
      response = await getReportAssetSummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value || null,
        args: body,
        date_range: dateRange
          ? `${getFormattedDateForFetch(
              dateRange[0].startDate
            )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
          : "",
        download: true,
      })
      setAllData(response)
      return response
    } catch (e) {
      return toggleNotification({
        content: "Failed to generate CSV (Asset Summary)",
        error: true,
      })
    }
  }
  const getData = async (pageNumber = 1, refresh = false) => {
    // if(download === true) {
    //   let response = null
    //   try {
    //     response = await getReportAssetSummary({
    //       token: token,
    //       companyId: company.value,
    // brandId: brand.value,
    //       args: body,
    //       date_range: dateRange
    //         ? `${getFormattedDateForFetch(
    //             dateRange[0].startDate
    //           )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
    //         : "",
    //       // offset: pageNumber == 1 ? "0" : `${(pageNumber - 1) * 50}`,
    //       download: true,
    //     })
    //     setAllData(response)
    //     return response;
    //   } catch (e) {
    //     return toggleNotification({ content: "error", error: true })
    //   }
    // }

    setPage(pageNumber)

    if (data[pageNumber]?.length > 0 && refresh === false)
      return data[pageNumber]

    let response = null
    try {
      response = await getReportAssetSummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value || null,
        args: body,
        // brand: brand ? brand.value : undefined,
        date_range: dateRange
          ? `${getFormattedDateForFetch(
              dateRange[0].startDate
            )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
          : "",
        offset: pageNumber == 1 ? "0" : `${(pageNumber - 1) * 50}`,
      })
    } catch (e) {
      return toggleNotification({ content: "error", error: true })
    }

    setData(prevData => {
      return {
        ...prevData,
        [pageNumber]: response,
      }
    })
    return response
  }

  useEffect(() => {
    // if (data !== initData && allData.length === 0) {
    if (data !== initData) {
      getAllData()
    }
    // }
  }, [data])
  useEffect(() => {
    if (allData.length !== 0) getAllData()
  }, [company])
  useEffect(() => {
    if (allData.length !== 0) getAllData()
  }, [brand])
  return (
    <ReportingAssetSummaryContext.Provider
      value={{
        page,
        setPage,
        data,
        setData,
        getData,
        allData,
        setParam,
        params,
        body,
      }}
    >
      {props.children}
    </ReportingAssetSummaryContext.Provider>
  )
}

export default ReportingAssetSummaryProvider
