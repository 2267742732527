/** @jsx jsx */

import { render } from "react-dom"
import { ReactGrid, Column, Row } from "@silevis/reactgrid"
import "@silevis/reactgrid/styles.css"
import "./sheet.css"
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState, useRef } from "react"
import { dashboardTrail } from "../../../utils/dashboardBreadcrumb"
import SalesChart from "../../../components/Elements/SalesChart"
import LayoutDashborad from "../../../components/Layout/Dashboard"
import DashboardTable from "../../../components/Table/DashboardTable"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import { toCapitalCase, useElementOnScreen } from "../../../utils"
import DataContext from "../../../context/Data/DataContext"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import {
  getAssetListing,
  getMyOrdersReport,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import Skeleton from "../../../components/Skeleton"
import Dashboard from "../../Dashboard"
import Pagination from "../../../components/Pagination"
import ReportingFilter from "../../../components/Reporting/Filter"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import ReportingCustomerSummaryContext from "../../../context/Reporting/ReportingCustomerSummary/ReportingCustomerSummaryContext"

import HighlightsWidget from "../../../components/Reporting/Widgets/HighlightsWidget"
import TopPerformersWidget from "../../../components/Reporting/Widgets/TopPerformersWidget"
import RecentOrdersWidget from "../../../components/Reporting/Widgets/RecentOrdersWidget"
import ReportingReportFilters from "../../../components/Reporting/ReportFilter"
import ReportingContainer from "../ReportingContainer"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import CircleAvatar from "../../../components/Elements/CircleAvatar"
import Button from "../../../components/Elements/Button"
import { CSVLink, CSVDownload } from "react-csv"
import ButtonCsv from "../../../components/Elements/ButtonCsv"
import Separator from "../../../components/Elements/Separator"
import { TabSubheading } from "../../../components/Tabs/TabElements"

function CustomerSummary() {
  const { currentUserCompaniesAsOp } = useContext(DataContext).data

  const { authentication } = useContext(AuthenticationContext)
  const { currentUserData } = authentication
  const { firstname } = currentUserData

  const { state } = useContext(StateContext)
  const {
    dateRange,
    setDateRange,
    company,
    brand,
    setCompany,
    dateRangeFetch,
    companies,
    loading,
  } = useContext(ReportingContext)

  const { getData, allData, page, setPage, params, setParam, body, data } =
    useContext(ReportingCustomerSummaryContext)

  const columnMap = {
    uid: "ID",
    status: "Active",
    email: "Email",
    job_title: "Job title",
    first_name: "First name",
    last_name: "Last name",
    company: "Company",
    address1: "Primary address",
    address2: "Primary unit",
    city: "Primary city",
    state: "Primary province",
    postal_code: "Primary postal code",
    phone: "Primary phone",
    stores: "Stores",
    user_groups: "User segments",
    first_visit: "First visit",
    last_visit: "Last visit *",
    brands_visited: "Brands visited *",
    products_visited: "Products visited",
    first_order_date: "First order",
    last_order_date: "Last order *",
    number_of_orders_physical: "Physical assets",
    number_of_orders_digital: "Digital assets",
    ordered_brands: "Ordered brands *",
    ordered_asset_tags: "Tags of ordered assets",
    ordered_asset_skus: "SKUs of ordered assets",
  }

  // const columns = [
  //   { columnId: "order_id", width: 150, resizable: true, reorderable: true },
  //   { columnId: "created", width: 150, resizable: true, reorderable: true },
  //   { columnId: "status", width: 150, resizable: true, reorderable: true },
  //   { columnId: "name_first", width: 150, resizable: true, reorderable: true },
  //   { columnId: "name_last", width: 150, resizable: true, reorderable: true },
  //   { columnId: "company", width: 150, resizable: true, reorderable: true },
  //   {
  //     columnId: "delivery_address",
  //     width: 150,
  //     resizable: true,
  //     reorderable: true,
  //   },
  //   {
  //     columnId: "delivery_city",
  //     width: 150,
  //     resizable: true,
  //     reorderable: true,
  //   },
  //   {
  //     columnId: "delivery_postal_code",
  //     width: 150,
  //     resizable: true,
  //     reorderable: true,
  //   },
  // ]

  const columns = Object.keys(columnMap).map(i =>
    Object.assign({
      columnId: i,
      width: 150,
      resizable: true,
      reorderable: true,
    })
  )

  const [tableData, setTableData] = useState(null)
  const [cols, setCols] = useState(columns)
  const [localLoading, setLocalLoading] = useState(loading)

  var options = {
    root: null,
    rootMargin: "20px",
    threshold: 1.0,
  }
  const [loadRef, loadMore] = useElementOnScreen(options)
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    if (localLoading !== loading) setLocalLoading(loading)
  }, [loading])

  useEffect(() => {
    if (!loading)
      getData(1).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false) /* CustomerSummary always displays full dataset */
        else setHasMore(false) /* CustomerSummary always displays full dataset */
      })
  }, [loading])

  useEffect(() => {
    if (tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false) /* CustomerSummary always displays full dataset */
        else setHasMore(false) /* CustomerSummary always displays full dataset */
      })
  }, [body])
  useEffect(() => {
    if (tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false) /* CustomerSummary always displays full dataset */
        else setHasMore(false) /* CustomerSummary always displays full dataset */
      })
  }, [company])
  useEffect(() => {
    if (!loading && tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false) /* CustomerSummary always displays full dataset */
        else setHasMore(false) /* CustomerSummary always displays full dataset */
      })
  }, [brand])
  useEffect(() => {
    if (tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false) /* CustomerSummary always displays full dataset */
        else setHasMore(false) /* CustomerSummary always displays full dataset */
      })
  }, [dateRangeFetch])
  // useEffect(() => {
  //   if (tableData !== null)
  //     getData(1, true).then(res => {
  //       setTableData(res)
  //       if (res !== null && res?.length < 49) setHasMore(false) /* CustomerSummary always displays full dataset */
  //       else setHasMore(false) /* CustomerSummary always displays full dataset */
  //     })
  // }, [params])

  const [csvData, setCSVData] = useState([])
  const [rows, setRows] = useState([])

  const getRows = (rows, columnsOrder, columnMap) => {
    return [
      {
        rowId: "header",
        height: 80,

        cells: columnsOrder.map(v => ({
          ...v,
          text: columnMap[v] || "N/A",
          type: "header",
          nonEditable: true,
          style: { paddingLeft: "8px" },
        })),
      },
      ...rows.map((v, k) => ({
        rowId: k,
        reorderable: true,
        height: 65,
        cells: columnsOrder.map(i => ({
          ...v,
          type: "text",
          text: v[i] ? v[i].toString() : "N/A",
          nonEditable: true,
          style: { paddingLeft: "5px" },
        })),
      })),
    ]
  }

  useEffect(() => {
    if (
      tableData !== null &&
      Array.isArray(tableData) &&
      tableData.length > 0
    ) {
      setCols([
        ...Object.keys(tableData[0]).map(i => ({
          columnId: i,
          width: 150,
          resizable: true,
          reorderable: true,
        })),
      ])
      const rows = getRows(
        tableData,
        [...Object.keys(tableData[0]).map(i => i)],
        columnMap
      )
      setRows([...rows])
    }
  }, [tableData])

  useEffect(() => {
    if (allData && allData.length > 0) {
      const rows = getRows(
        allData,
        [...Object.keys(allData[0]).map(i => i)],
        columnMap
      )
      let csvd = rows.map(v => v.cells.map(c => c.text))
      setCSVData([...csvd])
    }
  }, [allData])

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const rows = getRows(
        tableData,
        [...Object.keys(tableData[0]).map(i => i)],
        columnMap
      )
      let csvd = rows.map(v => v.cells.map(c => c.text))
      // console.log(csvd, rows)
      setCSVData([...csvd])
    } else if (tableData) {
      const temp = {
        uid: undefined,
        status: undefined,
        email: undefined,
        job_title: undefined,
        first_name: undefined,
        last_name: undefined,
        company: undefined,
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        postal_code: undefined,
        phone: undefined,
        stores: undefined,
        user_groups: undefined,
        first_visit: undefined,
        last_visit: undefined,
        brands_visited: undefined,
        products_visited: undefined,
        first_order_date: undefined,
        last_order_date: undefined,
        number_of_orders_physical: undefined,
        number_of_orders_digital: undefined,
        ordered_brands: undefined,
        ordered_asset_tags: undefined,
        ordered_asset_skus: undefined,
        undefined,
      }
      tableData.push(temp)
      const rows = getRows(
        tableData,
        [...Object.keys(tableData[0]).map(i => i)],
        columnMap
      )
      let csvd = rows.map(v => v.cells.map(c => c.text))
      setCSVData([...csvd])
    }
  }, [tableData, data, params])

  const handleColumnResize = (ci, width) => {
    setCols(prevColumns => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci)
      const resizedColumn = prevColumns[columnIndex]
      const updatedColumn = { ...resizedColumn, width }
      prevColumns[columnIndex] = updatedColumn
      return [...prevColumns]
    })
  }

  const reorderArray = (arr, idxs, to) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx))
    const targetIdx =
      Math.min(...idxs) < to
        ? (to += 1)
        : (to -= idxs.filter(idx => idx < to).length)
    const leftSide = arr.filter(
      (_, idx) => idx < targetIdx && !idxs.includes(idx)
    )
    const rightSide = arr.filter(
      (_, idx) => idx >= targetIdx && !idxs.includes(idx)
    )
    return [...leftSide, ...movedElements, ...rightSide]
  }

  const handleCanReorderRows = (targetRowId, rowIds) => {
    return targetRowId !== "header"
  }

  const handleColumnsReorder = (targetColumnId, columnIds) => {
    const to = columns.findIndex(column => column.columnId === targetColumnId)
    const columnIdxs = columnIds.map(columnId =>
      columns.findIndex(c => c.columnId === columnId)
    )
    // console.log(columnIdxs, to)
    // console.log(targetColumnId, columnIds)
    const reordered = reorderArray(cols, columnIdxs, to)
    // console.log(cols, reordered)

    setCols([...reordered])
    return true
  }

  const handleRowsReorder = (targetRowId, rowIds) => {
    const to = tableData.findIndex(person => person.id === targetRowId)
    const rowsIds = rowIds.map(id =>
      tableData.findIndex(person => person.id === id)
    )
    const reordered = reorderArray(tableData, rowsIds, to)
    setTableData([...reordered])
    return true
  }
  // console.log(dateRange)

  useEffect(async () => {
    if (loadMore && hasMore && tableData !== null) {
      getData(page + 1, true).then(res => {
        setPage(page + 1)

        if (res !== null && res?.length < 49) setHasMore(false) /* CustomerSummary always displays full dataset */
        else setHasMore(false) /* CustomerSummary always displays full dataset */

        setTableData(prev => [...prev, ...res])
      })
    }
  }, [loadMore, hasMore, tableData])

  const allValuesUndefined =
    tableData &&
    tableData[0] &&
    Object.values(tableData[0]).every(
      value => value === undefined || value === null
    )

  return (
    <>
      <TabSubheading text="A list of users who have ever visited, ordered or downloaded from your company." />
      <div>
        <ReportingReportFilters
          params={params}
          setParam={setParam}
          button={
            !loading && !localLoading ? (
              <div className="flex flex-col md:flex-row">
                <Button
                  red
                  onClick={() => {
                    setLocalLoading(true)
                    getData(1, true).then(res => {
                      setTableData(res)
                      setLocalLoading(false)
                    })
                  }}
                >
                  Apply Filters
                </Button>
                <p className="m-3">
                  These filters only apply to the CSV export and data displayed
                  below.
                </p>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row">
                <Button red disabled>
                  Apply Filters
                </Button>
                <p className="m-3">
                  These filters only apply to the CSV export and data displayed
                  below.
                </p>
              </div>
            )
          }
        />
      </div>
      {tableData !== null && localLoading === false ? (
        <div
          css={css`
            &&& {
              .rg-pane {
                .rg-cell.rg-header-cell {
                }
              }
            }
          `}
        >
          <div className="my-5">
            <ButtonCsv
              filename="customer-summary.csv"
              csvData={csvData}
              text={"Export as CSV"}
              allValuesUndefined={allValuesUndefined}
            />
          </div>
          {rows.length > 0 && !allValuesUndefined ? (
            <ReactGrid
              rows={rows}
              columns={cols}
              onColumnResized={handleColumnResize}
              onColumnsReordered={handleColumnsReorder}
              onRowsReordered={handleRowsReorder}
              canReorderRows={handleCanReorderRows}
              enableRowSelection
              enableColumnSelection
            />
          ) : (
            <p>{/*No results to show*/}</p>
          )}
        </div>
      ) : (
        <div className="w-full">
          <div className="my-5">
            <div>
              <Skeleton box />
            </div>
          </div>
          <Separator className="my-[30px]" />
          <Skeleton table />
        </div>
      )}
      <div className="load-more-area text-center my-6 py-6" ref={loadRef}>
        {hasMore ? (
          <div className="w-full overflow-hidden flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
          </div>
        ) : (
          tableData !== null && (
            <p className="text-reg-14">No more activity to show</p>
          )
        )}
      </div>
      {/* <Table
        columns={[
          "Order ID",
          "Created",
          "Status",
          "First name",
          "Last name",
          "Company",
          "Delivery address",
          "Delivery City",
          "Delivery Postcode",
        ]}
      >
        {tableData !== null &&
          tableData.map((item, k) => (
            <Tr key={k}>
              <Td>{item.order_id}</Td>
              <Td>{item.created}</Td>
              <Td>{item.status}</Td>
              <Td>{item.name_first}</Td>
              <Td>{item.name_last}</Td>
              <Td>{item.company}</Td>
              <Td>{item.delivery_address}</Td>
              <Td>{item.delivery_city}</Td>
              <Td>{item.delivery_postal_code}</Td>
            </Tr>
          ))}
      </Table> */}
    </>
  )
}

export default CustomerSummary
