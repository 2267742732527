/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState, useEffect } from "react"
import { red, redLight } from "../Styles"
import { CSVLink } from "react-csv"
import { DocumentReportIcon } from "@heroicons/react/outline"
function ButtonCsv(props) {
  const { csvData, text, filename, allValuesUndefined } = props
  // const [loading, setLoading] = useState(false)
  // const checkIfLoading = () => {
  //   if (csvData.length === 0) setLoading(true)
  // }
  // useEffect(() => {
  //   if (loading === true && csvData.length > 0) {setLoading(false)
  //     <CSVDownload data={csvData} target="_blank" />;
  //
  //   }
  // }, [csvData])
  if (allValuesUndefined) return null
  if (csvData.length === 0)
    return (
      <span
        css={css`
          width: auto;
          display: inline-flex;
          align-items: center;
          // padding: 15px;
          border-radius: 8px;
          padding: 1px 0;
          font-family: "KansasNew";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: #e43d30;
          height: auto;
          transition: 0.2s ease all;
          &:hover {
            color: #222;
          }
          cursor: pointer;
        `}
        // onClick={() => checkIfLoading()}
      >
        <div className="flex flex-col items-center justify-center mr-2">
          <div className="loader ease-linear rounded-full border border-t border-gray-200 h-4 w-4"></div>
        </div>
        Building CSV file
      </span>
    )
  return (
    <CSVLink
      data={csvData}
      filename={filename || "file.csv"}
      target="_blank"
      css={css`
        width: auto;
        display: inline-flex;
        // padding: 15px;
        border-radius: 8px;
        font-family: "KansasNew";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: #e43d30;
        height: auto;
        transition: 0.2s ease all;
        &:hover {
          color: #222;
        }
      `}
    >
      <DocumentReportIcon className="w-4 h-4 mr-2" />
      {text || "Export as CSV"}
    </CSVLink>
  )
}

export default ButtonCsv
