import React, { useState, useEffect, useContext } from "react"
import ReportingContext from "./ReportingContext"
import {
  getReportMyHighlights,
  getReportTopPerformers,
  getReportMyTopPerformers,
  getReportRecentOrders,
  getReportTotalOrders,
  getSubUserListing,
  getReportTotalVisits,
  getMyListing,
  getReportRecentVisits,
  getDeliveryLocationsByCompany,
} from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import DataContext from "../Data/DataContext"
import NotificationContext from "../Notification/NotificationContext"
import ReportingOrderSummaryProvider from "./ReportingOrderSummary/ReportingOrderSummaryProvider"
import ReportingCustomerSummaryProvider from "./ReportingCustomerSummary/ReportingCustomerSummaryProvider"
import ReportingAssetSummaryProvider from "./ReportingAssetSummary/ReportingAssetSummaryProvider"
import ReportingProductSummaryProvider from "./ReportingProductSummary/ReportingProductSummaryProvider"
import ReportingDownloadSummaryProvider from "./ReportingDownloadSummary/ReportingDownloadSummaryProvider"
import ReportingInventorySummaryProvider from "./ReportingInventorySummary/ReportingInventorySummaryProvider"
import ReportingStoreSummaryProvider from "./ReportingStoreSummary/ReportingStoreSummaryProvider"

export const ReportingProvider = props => {
  const { state } = useContext(StateContext)

  const { toggleNotification } = useContext(NotificationContext)
  const { currentUserCompaniesAsOp, myBrandsAsOptions, universalUserGroups } =
    useContext(DataContext).data

  // const {  } = useContext(DataContext)
  const { token } = state


  // console.log(universalUserGroups, "universalUserGroups1")
  const [company, setCompany] = useState(
    currentUserCompaniesAsOp ? currentUserCompaniesAsOp[0] : false
  )
  const [brand, setBrand] = useState(
    myBrandsAsOptions.length ? myBrandsAsOptions[0].value : false
  )

  useEffect(() => {
    console.log(brand, 'branndddd')
  }, [brand])




  const [companyUsers, setCompanyUsers] = useState([])
  const [loading, setLoading] = useState(true)

  let endDate = new Date()
  let startDate = new Date()
  startDate.setMonth(startDate.getMonth() - 3)

  const [dateRange, setDateRange] = useState([
    { startDate, key: "selection", endDate },
  ])
  const [deliveryLocations, setDeliveryLocations] = useState([])
  const [reports, setReports] = useState({
    highlights: null,
    top_performers: null,
    my_top_performers: null,
    recent_orders: null,
    order_summary: null,
    total_orders: null,
    recent_visits: null,
    total_visits: null,
  })

  useEffect(async () => {
    if (company && company.value) {
      const data = await getSubUserListing(token, company.value)
      const newData = []
      data.map((item, k) => {
        newData.push({ value: item.id, label: item.name })
      })
      setCompanyUsers(newData)

      const deliveryLocationsByCompany = await getDeliveryLocationsByCompany(
        token,
        company.value
      )
      if (
        deliveryLocationsByCompany &&
        Array.isArray(deliveryLocationsByCompany)
      ) {
        let arr = []
        deliveryLocationsByCompany.map((v, k) => {
          arr.push({
            label: v.title,
            value: v.id,
            location: v.location,
          })
        })
        setDeliveryLocations(arr)
      }
    }
  }, [company])

  useEffect(() => {
    if (currentUserCompaniesAsOp) {
      setCompany(currentUserCompaniesAsOp[0])
      setLoading(false)
    }
  }, [currentUserCompaniesAsOp])
  const [dateRangeFetch, setDateForFetch] = useState({
    startDate: undefined,
    endDate: undefined,
  })
  const getReport = async (report, id) => {
    let checkObj = {
      company: company.value,
      date_range: `${dateRangeFetch.startDate}-${dateRangeFetch.endDate}`,
      brand: brand?.value || null,
    }

    if (
      reports[report] !== null &&
      JSON.stringify(checkObj) === JSON.stringify(reports[`${report}_url`])
    )
      return reports[report]
    let response = null

    if (report === "highlights")
      response = await getReportMyHighlights(
        token,
        id ? id : company.value,
        dateRangeFetch.startDate,
        dateRangeFetch.endDate
      )

    if (report === "top_performers")
      response = await getReportTopPerformers(
        token,

        dateRangeFetch.startDate,
        dateRangeFetch.endDate
      )

    if (report === "my_top_performers")
      response = await getReportMyTopPerformers(
        token,
        dateRangeFetch.startDate,
        dateRangeFetch.endDate,
        id ? id : company.value,
      )

    if (report === "recent_orders")
      response = await getReportRecentOrders(
        token,
        id ? id : company.value,

        dateRangeFetch.startDate,
        dateRangeFetch.endDate,
        brand?.value || null,
      )

    if (report === "total_orders") {
      response = await getReportTotalOrders(
        token,
        id ? id : company.value,

        dateRangeFetch.startDate,
        dateRangeFetch.endDate
      )
    }
    if (report === "recent_visits") {
      response = await getReportRecentVisits(
        token,
        id ? id : company.value,

        dateRangeFetch.startDate,
        dateRangeFetch.endDate,
        brand?.value || null,
      )
    }
    if (report === "total_visits") {
      response = await getReportTotalVisits(
        token,
        id ? id : company.value,
        dateRangeFetch.startDate,
        dateRangeFetch.endDate,
        brand?.value || null,
      )
    }
    if (response !== null) {
      setReports(prevReports => {
        return {
          ...prevReports,
          [report]: response,
          [`${report}_url`]: {
            company: company.value,
            date_range: `${dateRangeFetch.startDate}-${dateRangeFetch.endDate}`,
          },
        }
      })
      return response
    } else {
      toggleNotification({ content: "Failed", error: true })
      return false
    }
  }

  const [segments, setSegments] = useState([])
  const [segmentsStores, setSegmentsStores] = useState([])
  const [segmentsUsers, setSegmentsUsers] = useState([])
  const [segmentsCompanies, setSegmentsCompanies] = useState([])
  const [segmentsRegions, setSegmentsRegions] = useState([])

  useEffect(async () => {
    if (universalUserGroups.length === 0) return
    let x = await getMyListing(token, "user_group", "default", "all")

    setSegments(
      [
        ...x.filter(
          f =>
            parseInt(f.field_group_company[0].target_id) ===
            parseInt(company.value)
        ),
        ...universalUserGroups,
      ]
        // .filter(f => f.field_group_users.length === 0)
        .map(i => Object.assign({ value: i.id, label: i.title }))
    )

    setSegmentsStores(
      [
        ...x.filter(
          f =>
            parseInt(f.field_group_company[0].target_id) ===
            parseInt(company.value)
        ),
      ].filter(f => f.field_group_stores.length > 0)
    )
    setSegmentsUsers(
      [
        ...x.filter(
          f =>
            parseInt(f.field_group_company[0].target_id) ===
            parseInt(company.value)
        ),
      ].filter(f => f.field_group_users.length > 0)
    )
    setSegmentsCompanies(
      [
        ...x.filter(
          f =>
            parseInt(f.field_group_company[0].target_id) ===
            parseInt(company.value)
        ),
      ].filter(f => f.field_group_companies.length > 0)
    )
    setSegmentsRegions(
      [
        ...x.filter(
          f =>
            parseInt(f.field_group_company[0].target_id) ===
            parseInt(company.value)
        ),
      ].filter(f => f.field_group_regions.length > 0)
    )
  }, [company, universalUserGroups])

  function getFormattedDateForFetch(date) {
    var year = date.getFullYear()

    var month = (1 + date.getMonth()).toString()
    month = month.length > 1 ? month : "0" + month

    var day = date.getDate().toString()
    day = day.length > 1 ? day : "0" + day

    return month + day + year
  }
  useEffect(() => {
    if (dateRange !== null) {
      let startDate = getFormattedDateForFetch(dateRange[0].startDate)
      let endDate = getFormattedDateForFetch(dateRange[0].endDate)
      setDateForFetch({ startDate, endDate })
    }
  }, [dateRange])
  useEffect(() => {
    let endDate = new Date()
    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 3)
    setDateRange([{ startDate, key: "selection", endDate }])
  }, [])

  return (
    <ReportingContext.Provider
      value={{
        dateRange,
        setDateRange,
        loading,
        setLoading,
        getReport,
        company,
        setCompany,
        companyUsers,
        companies: currentUserCompaniesAsOp,
        brands: myBrandsAsOptions,
        brand,
        setBrand,
        dateRangeFetch,
        reports,
        deliveryLocations,
        segments,
        segmentsStores,
        segmentsUsers,
        segmentsCompanies,
        segmentsRegions,
      }}
    >
      <ReportingOrderSummaryProvider>
        <ReportingAssetSummaryProvider>
          <ReportingProductSummaryProvider>
            <ReportingCustomerSummaryProvider>
              <ReportingStoreSummaryProvider>
                <ReportingInventorySummaryProvider>
                  <ReportingDownloadSummaryProvider>
                    {props.children}
                  </ReportingDownloadSummaryProvider>
                </ReportingInventorySummaryProvider>
              </ReportingStoreSummaryProvider>
            </ReportingCustomerSummaryProvider>
          </ReportingProductSummaryProvider>
        </ReportingAssetSummaryProvider>
      </ReportingOrderSummaryProvider>
    </ReportingContext.Provider>
  )
}

export default ReportingProvider
