import React, { useContext } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Analytics",
    },
  },
}

const labels = ["January", "February", "March", "April", "May", "June", "July"]

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [30, 60, 90, 120, 150, 180],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: [30, 60, 90, 120, 150, 180],
      borderColor: "rgb(255, 99, 142)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
}

export default function SalesChart() {
  const { currentUserData } = useContext(AuthenticationContext).authentication
  let data = {
    labels,
    datasets: currentUserData.brands.map((v, k) => ({
      label: v.title,
      data: [
        k + (1 + k * k) * 30,
        k + (1 + k * k) * 60 + 60,
        k + (1 + k * k) * 90,
        k + (1 + k * k) * 120,
        k + (1 + k * k) * 150,
        k + (1 + k * k) * 180,
      ],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    })),
  }

  return <Line options={options} data={data} />
}
