import React from "react"
import { navigate } from "gatsby"
import LayoutMinimal from "./Layout/Minimal"
import { XCircleIcon } from "@heroicons/react/solid"
import Button from "./Elements/Button"
function AccessDenied() {
  return (
    <LayoutMinimal
      title="Access Denied"
      metaTitle="Access Denied"
      metaDesc="Access Denied"
    >
      <div className="rounded-md bg-[#fce3e1] py-5 px-6 mb-4">
        <div className="text-reg-15 !leading-[1.5] red pb-1">
          <p>
            You don't have permission to view this page, but there's plenty more
            to see at O2O. Why not go back home?
          </p>
        </div>
      </div>
      <Button full red onClick={() => navigate("/")}>
        Back Home
      </Button>
      <div className="mb-[40px]"/>
    </LayoutMinimal>
  )
}

export default AccessDenied
