/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
// import SectionCard from "../../Cards/Dashboard/Reporting/SectionCard"
// import { Table, Tr, Td } from "../../Table/TableElements"
// import ReportingContext from "../../../context/Reporting/ReportingContext"
import TrendAnalysisWidgetChart from "./TrendAnalysisWidget/TrendAnalysisWidgetChart"
// import { getTrendsAnalysis } from "../../../helpers/DrupalHelper"
// import StateContext from "../../../context/state/StateContext"
import Skeleton from "../../Skeleton"
// import TrendAnalysisFilter from "../TrendAnalysisFilter"
// function getFormattedDateForFetch(date) {
//   var year = date.getFullYear()

//   var month = (1 + date.getMonth()).toString()
//   month = month.length > 1 ? month : "0" + month

//   var day = date.getDate().toString()
//   day = day.length > 1 ? day : "0" + day

//   return day + "/" + month + "/" + year
// }
const TrendAnalysisWidget = ({ data, dateRange }) => {

  return (
    <>
      <div className="w-[100%]">
        {data !== null && data ? (
          <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white">
            <TrendAnalysisWidgetChart data={data} dateRange={dateRange} />
          </div>
        ) : (
          <div>
            <div className="w-[100%]">
              <Skeleton height={500} rounded={"4px"} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TrendAnalysisWidget
