/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import SectionCard from "../../Cards/Dashboard/Reporting/SectionCard"
import { Table, Tr, Td } from "../../Table/TableElements"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import TotalOrdersWidgetChart from "./TotalOrdersWidget/TotalOrdersWidgetChart"
import WidgetHead from "./WidgetHead"
import Skeleton from "../../Skeleton"

const TotalOrdersWidget = props => {
  const { reports, getReport, dateRangeFetch, company, loading } =
    useContext(ReportingContext)
  const [data, setData] = useState(null)
  useEffect(() => {
    if (!loading) getReport("total_orders").then(res => setData(res))
  }, [loading])
  useEffect(() => {
    if (!loading && reports["total_orders"] !== null)
      getReport("total_orders").then(res => setData(res))
  }, [dateRangeFetch])
  useEffect(() => {
    if (!loading && reports["total_orders"] !== null)
      getReport("total_orders", company.value).then(res => setData(res))
  }, [company])
  return (
    <>
      <WidgetHead
        title={"Total Orders"}
        body={
          "Total orders for your company, including both digital and physical."
        }
      />
      <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white lg:h-full">
        {data !== null ? (
          <TotalOrdersWidgetChart data={data} />
        ) : (
          <Skeleton height={500} rounded={"4px"} />
        )}
      </div>
    </>
  )
}

export default TotalOrdersWidget
