/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import SectionCard from "../../Cards/Dashboard/Reporting/SectionCard"
import { Table, Tr, Td } from "../../Table/TableElements"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import ImageCardSmall from "../../Elements/ImageCardSmall"
import CircleAvatar from "../../Elements/CircleAvatar"
import { Link } from "gatsby"
import WidgetHead from "./WidgetHead"
import Skeleton from "../../Skeleton"

const RecentVisitsWidgets = props => {
  const { reports, getReport, company, brand, loading, dateRangeFetch } =
    useContext(ReportingContext)
  const [data, setData] = useState(null)
  useEffect(() => {
    if (!loading) getReport("recent_visits").then(res => setData(res))

  }, [loading])
  useEffect(() => {
    if (!loading && reports["recent_visits"] !== null)
      getReport("recent_visits", company.value, brand?.value || null).then(res => setData(res))

  }, [company, brand])
  useEffect(() => {
    if (!loading && reports["recent_visits"] !== null)
      getReport("recent_visits").then(res => setData(res))
  }, [dateRangeFetch])
  return (
    <div className="">
      <WidgetHead
        title={"Recent Visits"}
        body={"The latest 10 visits to your company's content."}
      />
      {data !== null ? (
        <div>
          {data.map((item, k) => {
            let type = item.visited.type
            return (
              <div
                // to={
                //   type == "brand"
                //     ? `/dashboard/my-organization`
                //     : `/dashboard/my-${type}s`
                // }
                // state={{ [`${type}_id`]: item.visited[type].id }}
                className="rounded-[6px] bg-white mb-[15px] px-5 py-3 border shadow-[0_10px_30px_rgba(0,0,0,.04)] hover-group group-hover block"
                key={k}
              >
                <div className="flex items-center">
                  {type === "brand" ? (
                    <CircleAvatar xs border image={item.visited[type].image} />
                  ) : (
                    <ImageCardSmall
                      size={40}
                      image={item.visited[type].image}
                    />
                  )}
                  <div className="flex flex-col ml-2">
                    <span className="red text-med-16 !text-[14px] mr-auto">
                      {item.visited[type].name}
                    </span>
                    <div className="text-reg-11 !leading-[1.4]">
                      <span className="text-black">{item.user.name}</span>{" "}
                      {item.user.companies.length > 0 && (
                        <>
                          from{" "}
                          <span className="text-black">
                            {item.user.companies[0].name}
                          </span>{" "}
                        </>
                      )}{" "}
                      viewed your {type} {item.visited[type].name}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <Skeleton table />
      )}
    </div>
  )
}

export default RecentVisitsWidgets
