/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect } from "react"
import { Tab } from "@headlessui/react"
import { classNames } from "../../utils"

export const TabSubheading = props => {
  const { text } = props

  return <div className="text-med-17 mb-6">{text}</div>
}

export const TabTitle = props => {
  const { name, icon, stroke } = props
  return (
    <div
      className={"group flex items-center"}
      css={css`
        &&& {
          &:hover {
            path {
              fill: ${stroke ? `none` : `#717171`};
            }
          }
        }
      `}
    >
      {icon && (
        <props.icon
          className={`mr-3 flex-shrink-0 ${
            !stroke ? `h-4 w-4` : `h-[1.1rem] w-[1.1rem]`
          }`}
          css={css`
            path {
              fill: ${!stroke ? `#222` : stroke ? `none` : `#222`};
              stroke-width: ${stroke ? `1.5px` : `/**/`};
            }
          `}
          aria-hidden="true"
        />
      )}
      {name}
    </div>
  )
}

export const Tabs = props => {
  const { tabs, selectedTab, setSelectedTab, setTopAsset, setInventoryStatus } =
    props
  // console.log(tabs)

  if (setSelectedTab) {
    return (
      <Tab.Group defaultIndex={0} selectedIndex={selectedTab}>
        <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
          {tabs.map((item, k) => (
            <Tab
              key={k}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "border-[#222] text-[#222]"
                    : "border-transparent text-[#222] hover:text-gray-500",
                  "whitespace-nowrap pb-[28px] px-1 border-b-2 text-med-16"
                )
              }
              onClick={() => {
                setTopAsset(null)
                setInventoryStatus(null)
                setSelectedTab(k)
              }}
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="pt-[30px]">
          {tabs.map((item, k) => {
            return <Tab.Panel key={k}>{item.content}</Tab.Panel>
          })}
        </Tab.Panels>
      </Tab.Group>
    )
  }
  return (
    <Tab.Group defaultIndex={0} selectedIndex={selectedTab}>
      <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
        {tabs.map((item, k) => (
          <Tab
            key={k}
            className={({ selected }) =>
              classNames(
                selected
                  ? "border-[#222] text-[#222]"
                  : "border-transparent text-[#222] hover:text-gray-500",
                "whitespace-nowrap pb-[28px] px-1 border-b-2 text-med-16"
              )
            }
            // onClick={() => setSelectedTab(k)}
          >
            {item.name}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="pt-[30px]">
        {tabs.map((item, k) => {
          return <Tab.Panel key={k}>{item.content}</Tab.Panel>
        })}
      </Tab.Panels>
    </Tab.Group>
  )
}
