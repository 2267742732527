import React, { useContext } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
  Filler,
} from "chart.js"
import { Line } from "react-chartjs-2"
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext"
import {
  red,
  redLight,
  blue,
  blueLight,
  green,
  greenLight,
  yellow,
  yellowLight,
} from "../../../Styles"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

defaults.font.family = "Jost"
defaults.font.weight = "400"
defaults.color = "#222"
defaults.plugins.tooltip.backgroundColor = "#E43D30"
defaults.plugins.tooltip.bodyColor = "#fff"
defaults.plugins.tooltip.titleColor = "#fff"
defaults.plugins.tooltip.padding = 10
defaults.plugins.tooltip.borderColor = "#E43D30"
defaults.plugins.tooltip.caretPadding = 13
defaults.plugins.tooltip.borderWidth = 1
defaults.plugins.tooltip.displayColors = false

defaults.elements.point.backgroundColor = '#fff'
defaults.elements.point.borderColor = red
defaults.elements.point.borderWidth = 1
defaults.elements.point.hoverBorderWidth = 1
defaults.elements.point.hitRadius = 1
defaults.elements.point.radius = 4
defaults.elements.point.hoverRadius = 5
defaults.elements.line.fill = "start"
defaults.elements.line.backgroundColor = context => {
  const ctx = context.chart.ctx
  const gradient = ctx.createLinearGradient(0, 0, 0, 200)
  gradient.addColorStop(0, "rgba(228, 61, 48, .5)")
  gradient.addColorStop(1, "rgba(252, 227 ,225, .5)")
  return gradient
}

defaults.elements.line.tension = 0.5
defaults.elements.line.borderWidth = 1
defaults.elements.line.borderColor = red
defaults.scale.grid.borderColor = 'rgba(0,0,0,0)'
defaults.scale.grid.color = 'rgba(0,0,0,.04)'

// defaults.scale.grid.backgroundColor = 'rgba(0,0,0,.05)'


// defaults.legend.display = falsew

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: false,
    },
  },
}

const labels = ["January", "February", "March", "April", "May", "June", "July"]

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: [30, 60, 90, 120, 150, 180],
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Dataset 2",
//       data: [30, 60, 90, 120, 150, 180],
//       borderColor: "rgb(255, 99, 142)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//   ],
// }

export default function TotalOrdersWidgetChart(props) {
  const { data } = props
  const { currentUserData } = useContext(AuthenticationContext).authentication


  // let labels = ["January", "February", "March", "April", "May", "June", "July"]
  // let labels =  [...Object.keys(data.days).map(i => i.substring(0, i.lastIndexOf("/")))]
  let chartData = []

  // console.log(data, 'data')
  // return null
  // if ("days" in data.total)
  let labels = [
    ...data.total.days.map(i => i.day),
  ]
  // if (true) chartData = [...data.total.days.map(i => i.total)]

  let physicalData = [
    ...data.total.days.map(i => i.physical),
  ]
  let digitalData = [
    ...data.total.days.map(i => i.digital),
  ]

  // Object.keys(data.total).map(i =>
  //   physicalData.push(data.total[i].physical)

  const datax = () => {
    return {
      labels,
      datasets: [
        {
          label: `Physical orders`,
          data: physicalData,
        },
        {
          label: `Digital orders`,
          data: digitalData,
          borderColor: green,
          backgroundColor: context => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 200)
            gradient.addColorStop(0, "rgba(0, 157, 79, .6)")
            gradient.addColorStop(1, "rgba(224, 240, 229, .5)")
            return gradient
          },
        },

      ]
      // datasets: [
      //   {
      //     label: `Total orders`,
      //     data: chartData,
      //     // backgroundColor: context => {
      //     //   const ctx = context.chart.ctx
      //     //   const gradient = ctx.createLinearGradient(0, 0, 0, 200)
      //     //   gradient.addColorStop(0, "rgba(250,174,50,1)")
      //     //   gradient.addColorStop(1, "rgba(250,174,50,0)")
      //     //   return gradient
      //     // },
      //     //
      //     // borderColor: "rgba(152,222,217,0.2)",

      //     // borderColor: red,
      //     // backgroundColor: 'redLight',
      //   },
      // ],
    }
  }

  return (
    <>
      <Line options={options} data={datax()} />
    </>
  )
}
