/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, {
  useContext, useEffect,
  //  useRef,
  useState
} from "react"
// import SectionCard from "../../Cards/Dashboard/Reporting/SectionCard"
// import { Table, Tr, Td } from "../../Table/TableElements"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import TotalVisitsWidgetChart from "./TotalVisitsWidget/TotalVisitsWidgetChart"
// import StateContext from "../../../context/state/StateContext"
// import { getReportTotalVisits } from "../../../helpers/DrupalHelper"
import WidgetHead from "./WidgetHead"
import Skeleton from "../../Skeleton"
const TotalVisitsWidget = props => {
  const { reports, getReport, dateRangeFetch, company, brand, loading } =
    useContext(ReportingContext)

  const [data, setData] = useState(null)

  useEffect(() => {
    if (!loading) getReport("total_visits").then(res => setData(res))
  }, [loading])
  useEffect(() => {
    if (!loading && reports["total_visits"] !== null)
      getReport("total_visits").then(res => setData(res))
  }, [dateRangeFetch])
  useEffect(() => {

    if (!loading && reports["total_visits"] !== null) getReport("total_visits", company.value, brand?.value || null).then(res => setData(res))
  }, [company, brand])

  return (
    <>
      <WidgetHead
        title={"Total Visits"}
        body={
          "Total visits of your company's brands, assets and products over time."
        }
      />
      <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white lg:h-full">
        {data ? (
          <TotalVisitsWidgetChart data={data} />
        ) : (
          <Skeleton height={500} rounded={"4px"} />
        )}
      </div>
    </>
  )
}

export default TotalVisitsWidget
