/** @jsx jsx */

import { render } from "react-dom"
import { ReactGrid, Column, Row } from "@silevis/reactgrid"
import "@silevis/reactgrid/styles.css"
import "./sheet.css"
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { dashboardTrail } from "../../../utils/dashboardBreadcrumb"
import SalesChart from "../../../components/Elements/SalesChart"
import LayoutDashborad from "../../../components/Layout/Dashboard"
import DashboardTable from "../../../components/Table/DashboardTable"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import DataContext from "../../../context/Data/DataContext"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import {
  getAssetListing,
  getMyOrdersReport,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import Skeleton from "../../../components/Skeleton"
import Dashboard from "../../Dashboard"
import Pagination from "../../../components/Pagination"
import ReportingFilter from "../../../components/Reporting/Filter"
import ReportingContext from "../../../context/Reporting/ReportingContext"

import HighlightsWidget from "../../../components/Reporting/Widgets/HighlightsWidget"
import TopPerformersWidget from "../../../components/Reporting/Widgets/TopPerformersWidget"
import RecentOrdersWidget from "../../../components/Reporting/Widgets/RecentOrdersWidget"
import ReportingReportFilters from "../../../components/Reporting/ReportFilter"
import ReportingContainer from "../ReportingContainer"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import CircleAvatar from "../../../components/Elements/CircleAvatar"
import Button from "../../../components/Elements/Button"
import { CSVLink, CSVDownload } from "react-csv"
import Separator from "../../../components/Elements/Separator"
import TrendAnalysisWidget from "../../../components/Reporting/Widgets/TrendAnalysisWidget"
import TrendAnalysisFilter from "../../../components/Reporting/TrendAnalysisFilter"
import { TabSubheading } from "../../../components/Tabs/TabElements"
// import { getFormattedDateForFetch } from '../../../utils/getFormattedDateForFetch'
import { getTrendsAnalysis } from "../../../helpers/DrupalHelper"
function TrendAnalysis() {
  const {
    reports,
    getReport,
    company,
    brand,
    dateRange,
    loading,
    dateRangeFetch,
  } = useContext(ReportingContext)
  const { state } = useContext(StateContext)
  const [data, setData] = useState(null)

  const [body, setBody] = useState({})

  function getFormattedDateForFetch(date) {
    var year = date.getFullYear()

    var month = (1 + date.getMonth()).toString()
    month = month.length > 1 ? month : "0" + month

    var day = date.getDate().toString()
    day = day.length > 1 ? day : "0" + day

    return day + "/" + month + "/" + year
  }
  let date_range = dateRange
    ? `${getFormattedDateForFetch(
        dateRange[0].startDate
      )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
    : "01/05/2022-01/09/2022"

  useEffect(() => {
    getTrendsAnalysis({
      token: state.token,
      companyId: company.value,
      brandId: brand.value,
      date_range: date_range,
      time_range: body?.time_range,
      args: { metric: "physical_orders" },
    }).then(res => setData(res))
  }, [])

  useEffect(() => {
    if (!loading && company.value && dateRange !== null && data !== null) {
      setData(false)

      getTrendsAnalysis({
        token: state.token,
        companyId: company.value,
        brandId: brand.value,
        date_range: date_range,
        time_range: body?.time_range,
        args: body,
      }).then(res => setData(res))
    }
  }, [dateRange])
  // useEffect(() => {
  //   if (!loading && company.value)
  //     getTrendsAnalysis({
  //       token: state.token,
  //       companyId: company.value,
  //       date_range: date_range,
  //       time_range: body?.time_range,
  //       args: body,
  //     }).then(res => setData(res))
  // }, [loading])
  useEffect(() => {
    // console.log('sss')
    if (
      !loading &&
      company.value &&
      Object.values(body).filter(Boolean).length &&
      data !== null
    ) {
      setData(false)
      getTrendsAnalysis({
        token: state.token,
        companyId: company.value,
        brandId: brand.value,
        date_range: date_range,
        time_range: body?.time_range,
        args: body,
      }).then(res => {
        setData(res)
      })
    }
  }, [body])
  useEffect(() => {
    if (!loading && company.value && data !== null) {
      setData(false)
      getTrendsAnalysis({
        token: state.token,
        companyId: company.value,
        brandId: brand?.value,
        date_range: date_range,
        time_range: body?.time_range,
        args: body,
      }).then(res => {
        setData(res)
      })
    }
  }, [company, brand])

  return (
    <>
      <TabSubheading text="Monitor trends over time by comparing your company’s performance with benchmark performance. Benchmarks are calculated as an average of each metric across all active companies on O2O." />
      <div className="">
        <TrendAnalysisFilter setBody={setBody} />
        <div>
          <TrendAnalysisWidget dateRange={date_range} data={data} />
        </div>
      </div>
    </>
  )
}

export default TrendAnalysis
