/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import SectionCard from "../../Cards/Dashboard/Reporting/SectionCard"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import { Table, Tr, Td } from "../../Table/TableElements"
import CircleAvatar from "../../Elements/CircleAvatar"
import MetaMini from "../../Elements/MetaMini"
import WidgetHead from "./WidgetHead"
import Skeleton from "../../Skeleton"
const MyTopPerformersWidget = props => {
  const { loading, reports, getReport, company, dateRangeFetch } =
    useContext(ReportingContext)
  const [data, setData] = useState(null)
  useEffect(() => {
    if (!loading) getReport("my_top_performers").then(res => setData(res))
  }, [loading])
  useEffect(() => {
    if (!loading && reports["my_top_performers"] !== null)
      getReport("my_top_performers").then(res => setData(res))
  }, [dateRangeFetch])
  useEffect(() => {
    if (!loading && reports["my_top_performers"] !== null)
      getReport("my_top_performers", company.value).then(res => setData(res))
  }, [company])
  const height = "65px"

  const Group = props => {
    const { children, grey } = props

    return (
      <div className={`${grey && `bg-[#fbfbfb]`} px-[15px]`}>{children}</div>
    )
  }
  return (
    <>
      <WidgetHead
        title={"Your Top Performers"}
        body={"Your top 10 performers, based on orders/downloads, site-wide during this date range."}
      />
      <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white">
        {data !== null ? (
          <div className="grid grid-cols-3">
            <Group>
              <Table columns={["Retailers"]} flush flushHead sm>
                {data?.retailers?.map((item, k) => {
                  return (
                    <Tr
                      css={css`
                        height: ${height};
                        min-height: ${height};
                        max-height: ${height};
                      `}
                      key={k}
                    >
                      <Td>
                        <div className="flex items-center">
                          {/* <CircleAvatar
                            image={item.logo}
                            border
                            xs
                            text={item.title}
                          /> */}
                          <span className="text-meta-sm">{item.title}</span>
                          <div className="ml-auto space-x-2">
                            <MetaMini
                              title={`${item.digital_count} downloads`}
                              grey
                            />
                            <MetaMini
                              title={`${item.physical_count} orders`}
                              grey
                            />
                          </div>
                        </div>
                      </Td>
                    </Tr>
                  )
                })}
              </Table>
            </Group>
            <Group grey>
              <div>
                <Table columns={["Formats"]} flush flushHead sm>
                  {data?.formats?.map((item, k) => {
                    return (
                      <Tr
                        css={css`
                          height: ${height};
                        `}
                        key={k}
                      >
                        <Td>
                          <div className="flex items-center">
                            <span className="text-meta-sm">{item.title}</span>
                            <div className="ml-auto space-x-2">
                              <MetaMini grey title={`${item.orders} orders`} />
                              <MetaMini grey title={`${item.downloads} downloads`} />
                            </div>
                          </div>
                        </Td>
                      </Tr>
                    )
                  })}
                </Table>
              </div>
            </Group>
            <Group>
              <Table columns={["Assets"]} flush flushHead sm>
                {data?.assets?.map((item, k) => {
                  return (
                    <Tr
                      css={css`
                        height: ${height};
                      `}
                      key={k}
                    >
                      <Td>
                        <div className="flex items-center">
                          <span className="text-meta-sm"> {item.title}</span>
                          <div className="ml-auto">
                            <MetaMini title={`${item.count} ${item.type === "physical" ? "orders" : "downloads"}`} grey />
                          </div>
                        </div>
                      </Td>
                    </Tr>
                  )
                })}
              </Table>
            </Group>
          </div>
        ) : (
          <div className="grid grid-cols-3">
            <Group>
              <Skeleton table />
            </Group>
            <Group grey>
              <Skeleton table />
            </Group>
            <Group>
              <Skeleton table />
            </Group>
          </div>
        )}
      </div>
    </>
  )
}

export default MyTopPerformersWidget
