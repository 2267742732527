import React, { useState, useEffect, useContext } from "react"
import ReportingOrderSummaryContext from "./ReportingOrderSummaryContext"
import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  getReportMyHighlights,
  getReportTopPerformers,
  getReportOrderSummary,
  getOrderCompaniesByCompany,
  getOrderUsersByCompany,
  getReportRecentOrders,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../state/StateContext"
import { list } from "postcss"
import DataContext from "../../Data/DataContext"
import ReportingContext from "../ReportingContext"
import ModalCart from "../../../components/Cart/ModalCart"
import AuthenticationContext from "../../authentication/AuthenticationContext"
import NotificationContext from "../../Notification/NotificationContext"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import AsyncFloatingSelect from "../../../components/Elements/Form/AsyncFloatingSelect"
function getFormattedDateForFetch(date) {
  var year = date.getFullYear()

  var month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : "0" + month

  var day = date.getDate().toString()
  day = day.length > 1 ? day : "0" + day

  return day + "/" + month + "/" + year
}
export const ReportingOrderSummaryProvider = props => {
  const {
    company,
    // companyUsers,
    dateRangeFetch,
    dateRange,
    deliveryLocations,
    segments,
    brand,
  } = useContext(ReportingContext)
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = state
  const {
    regionsAsOptions,
    myBrandsAsOptions,
    categoriesAsOptions,
    tagsAsOptions,
    myUsersAsOptions,
  } = useContext(DataContext).data

  const initData = {
    1: [],
  }
  const [body, setBody] = useState({})
  const [page, setPage] = useState(1)
  const [data, setData] = useState(initData)
  const [allData, setAllData] = useState([])
  const orderStatus = [
    { value: "open", label: "Open" },
    { value: "canceled", label: "Canceled" },
    { value: "archived", label: "Archived" },
  ]
  // useEffect(() => {
  //   companyUsers.length &&
  //     setParams(prevData => {
  //       return {
  //         ...prevData,
  //         user: { ...prevData.user, options: companyUsers },
  //       }
  //     })
  // }, [companyUsers])
  useEffect(() => {
    regionsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          regions: { ...prevData.regions, options: regionsAsOptions },
        }
      })
  }, [regionsAsOptions])
  useEffect(() => {
    myBrandsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          brand: { ...prevData.brand, options: myBrandsAsOptions },
        }
      })
  }, [myBrandsAsOptions])
  useEffect(() => {
    categoriesAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          asset_categories: {
            ...prevData.asset_categories,
            options: categoriesAsOptions,
          },
        }
      })
  }, [categoriesAsOptions])
  useEffect(() => {
    tagsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          asset_tags: { ...prevData.asset_tags, options: tagsAsOptions },
        }
      })
  }, [tagsAsOptions])
  useEffect(() => {
    deliveryLocations.length &&
      setParams(prevData => {
        return {
          ...prevData,
          delivery_location: {
            ...prevData.delivery_location,
            options: deliveryLocations,
          },
        }
      })
  }, [deliveryLocations])

  useEffect(() => {
    segments.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user_segments: {
            ...prevData.user_segments,
            options: segments,
          },
        }
      })
  }, [segments])

  useEffect(async () => {
    if (company?.value) {
      // const x = await getDownloadUsersByCompany(token, company.value)
      const y = await getOrderCompaniesByCompany(token, company.value)
      const x = await getOrderUsersByCompany(token, company.value)
      setParams(prevData => {
        return {
          ...prevData,
          user: { ...prevData.user, options: x.map(i => Object.assign({ label: i.first_name + " " + i.last_name, value: i.id })) },
          companies: { ...prevData.companies, options: y.map(i => Object.assign({ label: i.title, value: i.id })) },
        }
      })
    }
  }, [company])


  const [params, setParams] = useState({
    user: {
      value: [],
      component: FloatingSelect,
      label: "Order Users",
      props: { isMulti: true },
      options: [],
    },
    companies: {
      value: [],
      component: FloatingSelect,
      label: "Order Companies",
      options: [],
      props: {
        isMulti: true,
      },
    },
    regions: {
      value: [],
      component: FloatingSelect,
      label: "Order Regions",
      options: [],
      props: { isMulti: true },
    },
    user_segments: {
      value: [],
      component: FloatingSelect,
      label: "Order User Segments",
      options: segments,
      props: {
        isMulti: true,
      },
    },
    asset_tags: {
      value: [],
      component: FloatingSelect,
      label: "Asset Tags",
      options: [],
      props: { isMulti: true },
    },
    asset_categories: {
      value: [],
      component: FloatingSelect,
      label: "Asset Categories",
      props: { isMulti: true },
      options: [],
    },
    // brand: {
    //   value: null,
    //   component: FloatingSelect,
    //   label: "Brand",
    //   options: [],
    //   props: { isMulti: true },
    // },

    // asset_type: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Asset type",
    //   options: [],
    //   props: { isMulti: true },
    // },
    assets: {
      value: [],
      component: AsyncFloatingSelect,
      // props: {
      //   myEntitySuggest: true,
      //   body: { entity: "asset", bundle: ["default"] },
      // },
      label: "Assets",
      options: [],
      props: {
        isMulti: true,
        myEntitySuggest: true,
        provisionTempState: true,
        wrapMultiItems: true,
        body: { entity: "asset", bundle: ["default"] },
      },
    },

    // products: {
    //   value: [],
    //   component: AsyncFloatingSelect,
    //   // props: {
    //   //
    //   // },
    //   label: "Products",
    //   options: [],
    //   props: {
    //     isMulti: true,
    //     myEntitySuggest: true,
    //     body: { entity: "product", bundle: ["default"] },
    //     provisionTempState: true,
    //     wrapMultiItems: true,
    //   },
    // },
    // retailers: {
    //   value: [],
    //   component: AsyncFloatingSelect,
    //   label: "Retailers",
    //   props: {
    //     body: { entity: "retailer", bundle: ["default"] },
    //     isMulti: true,
    //     provisionTempState: true,
    //     wrapMultiItems: true,
    //     wrapMultiItems: true,
    //   },
    //   options: [],
    // },
    // delivery_location: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Delivery location",
    //   options: [],
    //   props: { subtitleSelector: "location" },
    // },
    order_status: {
      value: [],
      component: FloatingSelect,
      label: "Order Status",
      options: orderStatus,
      props: {
        isMulti: true,
      },
    },
  })

  useEffect(() => {
    let bodyVars = {}
    Object.keys(params).map((item, k) => {
      if (Array.isArray(params[item].value) && params[item].value.length > 0) {
        bodyVars[item] = params[item].value
      } else if (!Array.isArray(params[item].value) && params[item].value) {
        bodyVars[item] = params[item].value
      }
      setBody(bodyVars)
    })

    // console.log(params, "params")
    // console.log(bodyVars, "bodyVars")

    return bodyVars
  }, [params])

  const setParam = (param, value) => {
    // console.log("HI")
    setParams(prevData => {
      return {
        ...prevData,
        [param]: { ...prevData[param], value: value },
      }
    })
    // console.log({ ...params, [param]: { ...params[param], value: value } })
    return { ...params, [param]: { ...params[param], value: value } }
  }

  const getAllData = async () => {
    let response = null
    try {
      response = await getReportOrderSummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value || null,
        args: body,
        startDate: getFormattedDateForFetch(dateRange[0].startDate),
        endDate: getFormattedDateForFetch(dateRange[0].endDate),
        download: true,
      })
      setAllData(response)
    } catch (e) {

      // console.log(e, "failed to generate csv")
      return toggleNotification({
        content: "Failed to generate CSV (Order Summary)",
        error: true,
      })
    }
  }

  const getData = async (pageNumber = 1, refresh = false) => {
    setPage(pageNumber)

    if (data[pageNumber]?.length > 0 && refresh === false)
      return data[pageNumber]

    let response = null
    try {
      response = await getReportOrderSummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value || null,
        args: body,
        startDate: getFormattedDateForFetch(dateRange[0].startDate),
        endDate: getFormattedDateForFetch(dateRange[0].endDate),
        offset: pageNumber == 1 ? "0" : `${(pageNumber - 1) * 50}`,
      })
    } catch (e) {
      return toggleNotification({ content: "error", error: true })
    }

    setData(prevData => {
      return {
        ...prevData,
        [pageNumber]: response,
      }
    })
    return response
  }

  // useEffect(() => {
  //   if (data !== initData && allData.length === 0) getAllData()
  // }, [data])
  // useEffect(() => {
  //   if (allData.length !== 0) getAllData()
  // }, [company])
  // useEffect(() => {
  //   if (allData.length !== 0) getAllData()
  // }, [brand])

  useEffect(() => {
    // if (data !== initData && allData.length === 0) {
      if (data !== initData) {
        getAllData()
      }
    // }
  }, [data])
  useEffect(() => {
    if (allData.length !== 0) getAllData()
  }, [company, brand])
  return (
    <ReportingOrderSummaryContext.Provider
      value={{
        page,
        setPage,
        setBody,
        data,
        setData,
        getData,
        allData,
        setParam,
        params,
        body,
      }}
    >
      {props.children}
    </ReportingOrderSummaryContext.Provider>
  )
}

export default ReportingOrderSummaryProvider
