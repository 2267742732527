/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
// import DataContext from "../../../context/Data/DataContext"
// import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import {
  // getAssetListing,
  // getMyOrdersReport,
  // getMyListing,
  getMyWebhooks,
  postMyWebhooks,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import { Table, Tr, Td } from '../../../components/Table/TableElements'
import FloatingCheckbox from "../../../components/Elements/Form/FloatingCheckbox"
import FloatingInput from "../../../components/Elements/Form/FloatingInput"
import Button from "../../../components/Elements/Button"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import Skeleton from "../../../components/Skeleton"
import NotificationContext from "../../../context/Notification/NotificationContext"
// import { set } from "lodash"

const Webhooks = () => {

  const { toggleNotification } = useContext(NotificationContext)
  const { state } = useContext(StateContext)
  const { token } = state
  const [webhooks, setWebhooks] = useState({})
  const { company } = useContext(ReportingContext)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  const [formData, setFormData] = useState({
    order: {
      active: false,
      url: "",
    },
  })

  useEffect(async () => {
    if (!company && webhooks.length > 0) return
    const x = await getMyWebhooks(token, company.value)
    setWebhooks(x)
    setLoading(false)

  }, [company])

  useEffect(() => {
    if (Object.keys(webhooks).length === 0) return

    setFormData({
      ...webhooks
    })

  }, [webhooks])

  useEffect(() => {
    console.log(formData)
  }, [formData])

  const saveWebhooks = async () => {
    setSaving(true)
    const x = await postMyWebhooks(token, company.value, formData)
    setSaving(false)
    if (x.status === 200) {
      toggleNotification({ content: "Webhook configuration saved successfully." })
    } else {
      toggleNotification({ content: "An unexpected error occurred.", error: true })
    }
  }


  return <div className="mb-10">
    <div className="max-w-[500px] mb-8">
      <p className="text-reg-17">
        Enter the URL below where you want your data to be sent via our webhook
        (e.g. Slack - Incoming Webhook URL, MS PowerBI - REST API URL endpoint,
        etc.). Data will be sent in standard JSON format.
      </p>
    </div>
    <div className="max-w-[1000px] mb-8">

      {loading === true ? <Skeleton table />
        :
        <Table
          columns={[
            "Webhook",
            "Status",
            "Endpoint URL",
          ]}
        >
          <Tr>
            <Td>
              <h3 className="text-med-17">Orders</h3>
            </Td>
            <Td>
              <FloatingCheckbox
                hookForm={false}
                label="Active"
                value={formData.order.active}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    order: {
                      ...formData.order,
                      active: e.target.checked,
                    },
                  })
                }
              />
            </Td>
            <Td>
              <FloatingInput flush hookForm={false} name="endpoint_url" label="Endpoint URL"
                value={formData.order.url}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    order: {
                      ...formData.order,
                      url: e.target.value,
                    },
                  })
                }
              />
            </Td>
          </Tr>
        </Table>
      }

    </div>

    <Button red
      loading={saving}
      onClick={saveWebhooks}
    >Save</Button>
  </div>
}
export default Webhooks