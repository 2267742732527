import React, { useState } from "react"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import { classNames } from "../../../utils"

const FloatingRangeSlider = props => {
  const [sliderValue, setSliderValue] = useState([0, 100000])
  const { label, max, min, setParam, name } = props

  const handleValueChange = value => {
    setSliderValue(value)
    setParam(name, value)
    // handle your value change logic here
  }

  const handleLowerChange = event => {
    // Update state with the current input value
    setSliderValue([event.target.value, sliderValue[1]])
  }
  const handleLowerBlur = event => {
    const inputValue = event.target.value

    const numericValue = parseInt(inputValue, 10)

    // Check if numeric value is greater than 100000
    if (numericValue > max) {
      // Set numeric value to 100000 if it exceeds the maximum limit
      setSliderValue([max, sliderValue[1]])
      setParam(name, [Number(max), Number(sliderValue[1])])
    } else {
      if (numericValue < min) {
        setSliderValue([min, sliderValue[1]])
        setParam(name, [Number(min), Number(sliderValue[1])])
      }
      // Update state with validated input value
      else {
        setSliderValue([inputValue, sliderValue[1]])
        setParam(name, [Number(inputValue), Number(sliderValue[1])])
      }
    }
  }
  const handleUpperChange = event => {
    // Update state with the current input value
    setSliderValue([sliderValue[0], event.target.value])
  }
  const handleUpperBlur = event => {
    const inputValue = event.target.value

    const numericValue = parseInt(inputValue, 10)

    // Check if numeric value is greater than 100000
    if (numericValue > max) {
      // Set numeric value to 100000 if it exceeds the maximum limit
      setSliderValue([sliderValue[0], max])
      setParam(name, [Number(sliderValue[0]), Number(max)])
    } else {
      if (numericValue < min) {
        setSliderValue([sliderValue[0], min])
        setParam(name, [Number(sliderValue[0]), Number(min)])
      }
      // Update state with validated input value
      else {
        setSliderValue([sliderValue[0], Number(inputValue)])
        setParam(name, [Number(sliderValue[0]), Number(inputValue)])
      }
    }
  }
  const handleStyle = {
    backgroundColor: "#f00", // Customize handle color
    borderColor: "#f00", // Customize handle border color
    boxShadow: "0 0 5px #e43d30",
  }

  const railStyle = {
    backgroundColor: "rgba(204, 204, 204, 0.5)", // Customize rail color
  }

  const trackStyle = {
    backgroundColor: "#f00", // Customize track color
  }

  return (
    <div className={`mb-3 relative z-[3] w-full group text-[15px] `}>
      <div
        className={`gap-4 block h-[110px] flex flex-col py-[13px] text-[#EBEBEB] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer
          ${
            props.error
              ? "border-red-600 focus:border-red-600 focus:text-red-600 "
              : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
          }`}
      >
        <div className="flex justify-between">
          <div>
            <label
              htmlFor="min"
              className="block text-sm text-gray-500 text-center text-xs"
            >
              MIN
            </label>
            <input
              type="number"
              id="min"
              min={min}
              max={max}
              value={sliderValue[0]}
              onBlur={handleLowerBlur}
              onChange={handleLowerChange}
              className="block mx-auto mt-1 text-center w-17 h-6 text-base bg-white border border-gray-300 text-xs rounded-md focus:outline-none focus:border-blue-500 text-gray-500"
            />
          </div>
          <div>
            <label
              htmlFor="max"
              className="block text-sm text-gray-500 text-center text-xs"
            >
              MAX
            </label>
            <input
              type="number"
              id="max"
              min={min}
              max={max}
              value={sliderValue[1]}
              onChange={handleUpperChange}
              onBlur={handleUpperBlur}
              className="block mx-auto mt-1 text-center w-17 h-6 text-base bg-white border border-gray-300 text-xs rounded-md focus:outline-none focus:border-blue-500 text-gray-500"
            />
          </div>
        </div>
        <Slider
          range
          min={min}
          max={max}
          step={1}
          value={sliderValue}
          handleStyle={[handleStyle, handleStyle]}
          railStyle={railStyle}
          trackStyle={[trackStyle, trackStyle]}
          defaultValue={[0, 100000]}
          onChange={handleValueChange}
        />
      </div>

      <label
        for={"range"}
        className={classNames(
          "absolute duration-300 transform -translate-y-7 scale-75 top-[18px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-red-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
        )}
        style={{ color: "#717171" }}
      >
        {label}
      </label>
    </div>
  )
}

export default FloatingRangeSlider
