import React, { useContext } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
  Filler,
} from "chart.js"
import { Line } from "react-chartjs-2"
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext"
import {
  red,
  redLight,
  blue,
  blueLight,
  green,
  greenLight,
  yellow,
  yellowLight,
} from "../../../Styles"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

defaults.font.family = "Jost"
defaults.font.weight = "400"
defaults.color = "#222"
defaults.plugins.tooltip.backgroundColor = "#E43D30"
defaults.plugins.tooltip.bodyColor = "#fff"
defaults.plugins.tooltip.titleColor = "#fff"
defaults.plugins.tooltip.padding = 10
defaults.plugins.tooltip.borderColor = "#E43D30"
defaults.plugins.tooltip.caretPadding = 13
defaults.plugins.tooltip.borderWidth = 1
defaults.plugins.tooltip.displayColors = false
// defaults.layout.padding.top = 500
defaults.elements.point.backgroundColor = "#fff"
defaults.elements.point.borderColor = red
defaults.elements.point.borderWidth = 1
defaults.elements.point.hoverBorderWidth = 1
defaults.elements.point.hitRadius = 1
defaults.elements.point.radius = 4
defaults.elements.point.hoverRadius = 5
defaults.elements.line.fill = "start"
defaults.elements.line.backgroundColor = context => {
  const ctx = context.chart.ctx
  const gradient = ctx.createLinearGradient(0, 0, 0, 200)
  gradient.addColorStop(0, "rgba(228, 61, 48, .5)")
  gradient.addColorStop(1, "rgba(252, 227 ,225, .5)")
  return gradient
}

defaults.elements.line.tension = 0.4
defaults.elements.line.borderWidth = 1
defaults.elements.line.borderColor = red
defaults.scale.grid.borderColor = "rgba(0,0,0,0)"
defaults.scale.grid.color = "rgba(0,0,0,.04)"


export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
      }
    },
    title: {
      display: false,
    },
  },
}


const labels = ["January", "February", "March", "April", "May", "June", "July"]

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: [30, 60, 90, 120, 150, 180],
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Dataset 2",
//       data: [30, 60, 90, 120, 150, 180],
//       borderColor: "rgb(255, 99, 142)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//   ],
// }

export default function TotalVisitsWidgetChart(props) {
  const { data } = props
  const { currentUserData } = useContext(AuthenticationContext).authentication

  let labels = [...Object.keys(data.days).map(i => i.substring(0, i.lastIndexOf("/")))]

  let datax = {
    labels,
    datasets: [
      {
        label: `Product Visits`,
        data: [...Object.values(data.days).map(i => i.product_visits)],
        borderColor: green,
        backgroundColor: context => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 0, 0, 200)
          gradient.addColorStop(0, "rgba(0, 157, 79, .6)")
          gradient.addColorStop(1, "rgba(224, 240, 229, .5)")
          return gradient
        },
      },
      {
        label: `Asset Visits`,
        data: [...Object.values(data.days).map(i => i.asset_visits)],
        borderColor: blue,
        backgroundColor: context => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 0, 0, 200)
          gradient.addColorStop(0, "rgba(0, 51, 161, .5)")
          gradient.addColorStop(1, "rgba(0, 51, 161, .2)")
          return gradient
        },
      },
      {
        label: `Brand Visits`,
        data: [...Object.values(data.days).map(i => i.brand_visits)],
        // borderColor: red,
        // backgroundColor: "#FF9764",
      },
    ],
  }

  return <Line options={options} data={datax} />
}
