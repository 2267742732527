/** @jsx jsx */
import { css, jsx } from "@emotion/react"
// import React, { useContext, useEffect, useRef, useState } from "react"
// import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
// import AsyncFloatingSelect from "../../components/Elements/Form/AsyncFloatingSelect"
// import FloatingDateRange from "../../components/Elements/Form/FloatingDateRange"

const ReportingReportFilter = props => {
  const {
    params,
    setParam,
    button,
    selectLabel,
    selectLabelType,
    selectedOpt,
  } = props

  return (
    <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white">
      <div className="grid grid-cols-4 gap-4">
        {Object.keys(params)
          .filter(i => i !== "brands")
          .filter(i => i !== "brand")
          .map((i, k) => {
            const item = params[i]
            const { label, value, options, max } = item
            if (item.component && item.type !== "range")
              return (
                <div
                  css={css`
                    > * {
                      margin-bottom: 0 !important;
                    }
                  `}
                >
                  <item.component
                    label={label}
                    name={i}
                    defaultValue={
                      selectedOpt &&
                      label === selectLabelType && {
                        value: "low_stock",
                        label: "Low Stock",
                      }
                    }
                    // value={value}
                    tempValue={
                      selectLabel &&
                      label === selectLabelType && {
                        label: selectLabel,
                        value: value && value[0],
                      }
                    }
                    options={options || {}}
                    onChange={e => {
                      if (Array.isArray(e)) {
                        let vals = []
                        if (selectLabel && label === selectLabelType) {
                          vals.unshift(value && value[0])
                        }
                        e.map((item, k) => {
                          if (i === "regions") vals.push(item.region_code)
                          else if (typeof item === "object")
                            vals.push(item.value)
                          else item && vals.push(item)
                        })
                        setParam(i, vals)
                      } else {
                        setParam(i, e.value)
                      }
                    }}
                    {...item.props}
                  />
                </div>
              )
            else if (item.component && item.type === "range") {
              return (
                <div
                  css={css`
                    > * {
                      margin-bottom: 0 !important;
                    }
                  `}
                >
                  <item.component
                    name={i}
                    setParam={setParam}
                    label={label}
                    max={100000}
                    min={0}
                    value={value}
                  />
                </div>
              )
            }
            return null
          })}
      </div>
      <div className="mt-4">{button}</div>

      <div className="flex flex-col md:flex-row md:items-center md:justify-end space-y-[15px] md:space-y-0 md:space-x-[15px] w-full">
        <div className="md:grow md:!mr-auto"></div>
      </div>
      <div className="md:grow"></div>
    </div>
  )
}

export default ReportingReportFilter
