/** @jsx jsx */

// import { render } from "react-dom"
import {
  ReactGrid,
  //  Column, Row
} from "@silevis/reactgrid"
import "@silevis/reactgrid/styles.css"
import "./sheet.css"
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState, useRef } from "react"
// import { dashboardTrail } from "../../../utils/dashboardBreadcrumb"
// import SalesChart from "../../../components/Elements/SalesChart"
// import LayoutDashborad from "../../../components/Layout/Dashboard"
// import DashboardTable from "../../../components/Table/DashboardTable"
// import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
// import DataContext from "../../../context/Data/DataContext"
// import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
// import {
//   getAssetListing,
//   getMyOrdersReport,
// } from "../../../helpers/DrupalHelper"
// import StateContext from "../../../context/state/StateContext"
import Skeleton from "../../../components/Skeleton"
// import Dashboard from "../../Dashboard"
// import Pagination from "../../../components/Pagination"
// import ReportingFilter from "../../../components/Reporting/Filter"
import ReportingContext from "../../../context/Reporting/ReportingContext"
import ReportingDownloadSummaryContext from "../../../context/Reporting/ReportingDownloadSummary/ReportingDownloadSummaryContext"

// import HighlightsWidget from "../../../components/Reporting/Widgets/HighlightsWidget"
// import TopPerformersWidget from "../../../components/Reporting/Widgets/TopPerformersWidget"
// import RecentOrdersWidget from "../../../components/Reporting/Widgets/RecentOrdersWidget"
import ReportingReportFilters from "../../../components/Reporting/ReportFilter"
// import ReportingContainer from "../ReportingContainer"
// import { Table, Tr, Td } from "../../../components/Table/TableElements"
// import CircleAvatar from "../../../components/Elements/CircleAvatar"
import Button from "../../../components/Elements/Button"
// import { CSVLink, CSVDownload } from "react-csv"
import ButtonCsv from "../../../components/Elements/ButtonCsv"
import Separator from "../../../components/Elements/Separator"
import { TabSubheading } from "../../../components/Tabs/TabElements"
import {
  //  toCapitalCase,
  useElementOnScreen,
} from "../../../utils"

function DownloadSummary() {
  // const { currentUserCompaniesAsOp } = useContext(DataContext).data

  // const { authentication } = useContext(AuthenticationContext)
  // const { currentUserData } = authentication
  // const { firstname } = currentUserData

  // const { state } = useContext(StateContext)
  const {
    // dateRange,
    dateRangeFetch,
    // setDateRange,
    company,
    brand,
    // setCompany,
    // companies,
    loading,
  } = useContext(ReportingContext)
  const {
    getData,
    allData,
    data,
    page,
    setPage,
    params,
    setParam,
    body,
    // data
  } = useContext(ReportingDownloadSummaryContext)
  const columnMap = {
    order_id: "ID",
    created: "Created *",
    asset_cost: "Asset Costs",
    asset_name: "Asset Name",
    asset_brand: "Asset Brand *",
    asset_company: "Asset Company",
    asset_sku: "Asset SKU",
    name_first: "First name",
    name_last: "Last name",
    company: "Company",
    delivery_address: "Street address",
    address2: "Unit",
    delivery_city: "City",
    delivery_state: "Province",
    delivery_postal_code: "Postal code",
  }
  const columns = Object.keys(columnMap).map(i =>
    Object.assign({
      columnId: i,
      width: 150,
      resizable: true,
      reorderable: true,
    })
  )

  const [tableData, setTableData] = useState(null)
  const [cols, setCols] = useState(columns)
  // const [columnMap, setColumnMap] = useState(columnMap1)
  const [localLoading, setLocalLoading] = useState(loading)
  // const [allData, setAllData] = useState([])
  var options = {
    root: null,
    rootMargin: "20px",
    threshold: 1.0,
  }
  const [loadRef, loadMore] = useElementOnScreen(options)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    if (localLoading !== loading) setLocalLoading(loading)
  }, [loading])

  useEffect(() => {
    if (!loading)
      getData(1).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false)
        else setHasMore(true)
      })
  }, [loading])

  useEffect(() => {
    if (tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false)
        else setHasMore(true)
      })
  }, [body])
  useEffect(() => {
    if (tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false)
        else setHasMore(true)
      })
  }, [company])

  useEffect(() => {
    if (!loading && tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false)
        else setHasMore(true)
      })
  }, [brand])
  useEffect(() => {
    if (tableData !== null)
      getData(1, true).then(res => {
        setTableData(res)
        if (res !== null && res?.length < 49) setHasMore(false)
        else setHasMore(true)
      })
  }, [dateRangeFetch])
  // useEffect(() => {
  //   if (tableData !== null)
  //     getData(1, true).then(res => {
  //       setTableData(res)
  //       if (res !== null && res?.length < 49) setHasMore(false)
  //       else setHasMore(true)
  //     })
  // }, [params])
  // useEffect(() => {
  //   setCols(tableData)
  // }, tableData)
  const [csvData, setCSVData] = useState([])
  const [rows, setRows] = useState([])

  const getRows = (rows, columnsOrder, columnMap) => {
    return [
      {
        rowId: "header",
        height: 80,

        cells: columnsOrder.map(v => ({
          ...v,
          text: columnMap[v],
          type: "header",
          nonEditable: true,
          style: { paddingLeft: "8px" },
        })),
      },
      ...rows.map((v, k) => ({
        rowId: k,
        reorderable: true,
        height: 65,
        cells: columnsOrder.map(i => ({
          ...v,
          type: "text",
          text: v[i] ? v[i].toString() : "",
          nonEditable: true,
          style: { paddingLeft: "5px" },
        })),
      })),
    ]
  }

  useEffect(() => {
    if (
      tableData !== null &&
      Array.isArray(tableData) &&
      tableData.length > 0
    ) {
      // let colMap = {}
      // Object.keys(tableData[0]).map(v => {
      //   let a = `${toCapitalCase(v.split("_")[0])} ${toCapitalCase(
      //     v.split("_")[1]
      //   )}`
      //   Object.assign(colMap, { [v]: a })
      // })
      // setColumnMap(colMap)
      setCols([
        ...Object.keys(tableData[0]).map(i => ({
          columnId: i,
          width: 150,
          resizable: true,
          reorderable: true,
        })),
      ])
      const rows = getRows(
        tableData,
        [...Object.keys(tableData[0]).map(i => i)],
        columnMap
      )
      setRows([...rows])

      // if(allData.length === 0){
      // getData(null, false, true).then(res => {
      //   console.log(res, "res")
      //   setAllData(res)
      // })}
      // let csvd = rows.map(v => v.cells.map(c => c.text))
      // console.log(csvd, rows)
      // setCSVData([...csvd])
    }
  }, [tableData])

  useEffect(() => {
    if (allData && allData.length > 0) {
      const rows = getRows(
        allData,
        [...Object.keys(allData[0]).map(i => i)],
        columnMap
      )
      let csvd = rows.map(v => v.cells.map(c => c.text))
      setCSVData([...csvd])
    }
  }, [allData])

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const rows = getRows(
        tableData,
        [...Object.keys(tableData[0]).map(i => i)],
        columnMap
      )
      let csvd = rows.map(v => v.cells.map(c => c.text))
      // console.log(csvd, rows)
      setCSVData([...csvd])
    } else if (tableData) {
      const temp = {
        undefined,
        order_id: undefined,
        created: undefined,
        asset_cost: undefined,
        asset_name: undefined,
        asset_brand: undefined,
        asset_company: undefined,
        asset_sku: undefined,
        name_first: undefined,
        name_last: undefined,
        company: undefined,
        delivery_address: undefined,
        address2: undefined,
        delivery_city: undefined,
        delivery_state: undefined,
        delivery_postal_code: undefined,
      }
      tableData.push(temp)
      const rows = getRows(
        tableData,
        [...Object.keys(tableData[0]).map(i => i)],
        columnMap
      )
      let csvd = rows.map(v => v.cells.map(c => c.text))
      setCSVData([...csvd])
    }
  }, [tableData, data, params])

  // const headerRow: Row = ;

  const handleColumnResize = (ci, width) => {
    setCols(prevColumns => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci)
      const resizedColumn = prevColumns[columnIndex]
      const updatedColumn = { ...resizedColumn, width }
      prevColumns[columnIndex] = updatedColumn
      return [...prevColumns]
    })
  }

  const reorderArray = (arr, idxs, to) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx))
    const targetIdx =
      Math.min(...idxs) < to
        ? (to += 1)
        : (to -= idxs.filter(idx => idx < to).length)
    const leftSide = arr.filter(
      (_, idx) => idx < targetIdx && !idxs.includes(idx)
    )
    const rightSide = arr.filter(
      (_, idx) => idx >= targetIdx && !idxs.includes(idx)
    )
    return [...leftSide, ...movedElements, ...rightSide]
  }

  const handleCanReorderRows = (targetRowId, rowIds) => {
    return targetRowId !== "header"
  }

  const handleColumnsReorder = (targetColumnId, columnIds) => {
    const to = columns.findIndex(column => column.columnId === targetColumnId)
    const columnIdxs = columnIds.map(columnId =>
      columns.findIndex(c => c.columnId === columnId)
    )
    // console.log(columnIdxs, to)
    // console.log(targetColumnId, columnIds)
    const reordered = reorderArray(cols, columnIdxs, to)
    // console.log(cols, reordered)

    setCols([...reordered])
    return true
  }

  const handleRowsReorder = (targetRowId, rowIds) => {
    const to = tableData.findIndex(person => person.id === targetRowId)
    const rowsIds = rowIds.map(id =>
      tableData.findIndex(person => person.id === id)
    )
    const reordered = reorderArray(tableData, rowsIds, to)
    setTableData([...reordered])
    return true
  }
  // console.log(dateRange)

  useEffect(async () => {
    if (loadMore && hasMore && tableData !== null) {
      getData(page + 1, true).then(res => {
        setPage(page + 1)

        if (res !== null && res?.length < 49) setHasMore(false)
        else setHasMore(true)

        setTableData(prev => [...prev, ...res])
      })
    }
  }, [loadMore, hasMore, tableData])

  const allValuesUndefined =
    tableData &&
    tableData[0] &&
    Object.values(tableData[0]).every(
      value => value === undefined || value === null
    )

  return (
    <>
      <TabSubheading text="A list of  your company’s digital assets as line items from each download" />
      <div>
        <ReportingReportFilters
          params={params}
          setParam={setParam}
          button={
            !loading && !localLoading ? (
              <div className="flex flex-col md:flex-row">
                <Button
                  red
                  onClick={() => {
                    setLocalLoading(true)
                    getData(1, true).then(res => {
                      setTableData(res)
                      setLocalLoading(false)
                    })
                  }}
                >
                  Apply Filters
                </Button>
                <p className="m-3">
                  These filters only apply to the CSV export and data displayed
                  below.
                </p>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row">
                <Button red disabled>
                  Apply Filters
                </Button>
                <p className="m-3">
                  These filters only apply to the CSV export and data displayed
                  below.
                </p>
              </div>
            )
          }
        />
      </div>
      {tableData !== null && localLoading === false ? (
        <div
          css={css`
            &&& {
              .rg-pane {
                .rg-cell.rg-header-cell {
                }
              }
            }
          `}
        >
          <div className="my-5">
            <ButtonCsv
              filename="download-summary.csv"
              csvData={csvData}
              text={"Export as CSV"}
              allValuesUndefined={allValuesUndefined}
            />
          </div>
          {rows.length > 0 && !allValuesUndefined ? (
            <ReactGrid
              rows={rows}
              columns={cols}
              onColumnResized={handleColumnResize}
              onColumnsReordered={handleColumnsReorder}
              onRowsReordered={handleRowsReorder}
              canReorderRows={handleCanReorderRows}
              enableRowSelection
              enableColumnSelection
            />
          ) : (
            <p>{/*No results to show*/}</p>
          )}
        </div>
      ) : (
        <div>
          <Skeleton table />
          <Separator className="my-[30px]" />

          <Skeleton table />
        </div>
      )}
      <div className="load-more-area text-center my-6 py-6" ref={loadRef}>
        {hasMore ? (
          <div className="w-full overflow-hidden flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
          </div>
        ) : (
          tableData !== null && (
            <p className="text-reg-14">No more activity to show</p>
          )
        )}
      </div>
      {/* <Table
        columns={[
          "Order ID",
          "Created",
          "Status",
          "First name",
          "Last name",
          "Company",
          "Delivery address",
          "Delivery City",
          "Delivery Postcode",
        ]}
      >
        {tableData !== null &&
          tableData.map((item, k) => (
            <Tr key={k}>
              <Td>{item.order_id}</Td>
              <Td>{item.created}</Td>
              <Td>{item.status}</Td>
              <Td>{item.name_first}</Td>
              <Td>{item.name_last}</Td>
              <Td>{item.company}</Td>
              <Td>{item.delivery_address}</Td>
              <Td>{item.delivery_city}</Td>
              <Td>{item.delivery_postal_code}</Td>
            </Tr>
          ))}
      </Table> */}
    </>
  )
}

export default DownloadSummary
