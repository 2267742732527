import React, { useState, useEffect, useContext } from "react"
import ReportingStoreSummaryContext from "./ReportingStoreSummaryContext"
// import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  // getReportMyHighlights,
  // getReportTopPerformers,
  getReportStoreSummary,
  getStoreCities,
  getStoreCompanies,
  getStoreRegions,
  // getReportRecentOrders,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../state/StateContext"
// import { list } from "postcss"
import DataContext from "../../Data/DataContext"
import ReportingContext from "../ReportingContext"
// import ModalCart from "../../../components/Cart/ModalCart"
// import AuthenticationContext from "../../authentication/AuthenticationContext"
import NotificationContext from "../../Notification/NotificationContext"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import AsyncFloatingSelect from "../../../components/Elements/Form/AsyncFloatingSelect"
function getFormattedDateForFetch(date) {
  var year = date.getFullYear()

  var month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : "0" + month

  var day = date.getDate().toString()
  day = day.length > 1 ? day : "0" + day

  return day + "/" + month + "/" + year
}
export const ReportingStoreSummaryProvider = props => {
  const {
    company,
    brand,
    companyUsers,
    dateRange,
    deliveryLocations,
    segments,
  } = useContext(ReportingContext)
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = state
  const {
    // regionsAsOptions,
    // myBrandsAsOptions,
    categoriesAsOptions,
    tagsAsOptions,

    // myUsersAsOptions,
  } = useContext(DataContext).data
  const initData = {
    1: [],
  }
  const [body, setBody] = useState({})
  const [page, setPage] = useState(1)
  const [allData, setAllData] = useState([])
  const [storeCompanies, setStoreCompanies] = useState([])
  const [storeRegions, setStoreRegions] = useState([])
  const [storeCities, setStoreCities] = useState([])
  const [data, setData] = useState(initData)

  useEffect(() => {
    getStoreCompanies(token).then(res => {
      let storeCompaniesAsOptions = []
      res.map((i, k) => {
        storeCompaniesAsOptions.push({ value: i.value, label: i.label })
      })
      setStoreCompanies(storeCompaniesAsOptions)
    })
    getStoreRegions(token).then(res => {
      let storeRegionsAsOptions = []
      res.map((i, k) => {
        storeRegionsAsOptions.push({ value: i.region, label: i.region })
      })
      setStoreRegions(storeRegionsAsOptions)
    })
    getStoreCities(token).then(res => {
      let storeCitiesAsOptions = []
      res.map((i, k) => {
        storeCitiesAsOptions.push({ value: i.city, label: i.city })
      })
      setStoreCities(storeCitiesAsOptions)
    })
  }, [])
  // console.log({ storeRegions })
  useEffect(() => {
    companyUsers.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user: { ...prevData.user, options: companyUsers },
        }
      })
  }, [companyUsers])

  // useEffect(() => {
  //   myBrandsAsOptions.length &&
  //     setParams(prevData => {
  //       return {
  //         ...prevData,
  //         brands: { ...prevData.brands, options: myBrandsAsOptions },
  //       }
  //     })
  // }, [myBrandsAsOptions])
  useEffect(() => {
    categoriesAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          categories: {
            ...prevData.categories,
            options: categoriesAsOptions,
          },
        }
      })
  }, [categoriesAsOptions])
  useEffect(() => {
    tagsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          tags: { ...prevData.tags, options: tagsAsOptions },
        }
      })
  }, [tagsAsOptions])

  useEffect(() => {
    storeCompanies.length &&
      setParams(prevData => {
        return {
          ...prevData,
          companies: {
            ...prevData.companies,
            options: storeCompanies,
          },
        }
      })
  }, [storeCompanies])

  useEffect(() => {
    storeCities.length &&
      setParams(prevData => {
        return {
          ...prevData,
          cities: {
            ...prevData.cities,
            options: storeCities,
          },
        }
      })
  }, [storeCities])

  useEffect(() => {
    storeRegions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          region: {
            ...prevData.region,
            options: storeRegions,
          },
        }
      })
  }, [storeRegions])

  useEffect(() => {
    deliveryLocations.length &&
      setParams(prevData => {
        return {
          ...prevData,
          delivery_location: {
            ...prevData.delivery_location,
            options: deliveryLocations,
          },
        }
      })
  }, [deliveryLocations])

  useEffect(() => {
    segments.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user_segments: {
            ...prevData.user_segments,
            options: segments,
          },
        }
      })
  }, [segments])

  const [params, setParams] = useState({
    companies: {
      value: [],
      component: FloatingSelect,
      label: "Store Companies",
      options: [],
      props: {
        isMulti: true,
      },
    },
    region: {
      value: [],
      component: FloatingSelect,
      label: "Store Regions",
      options: [],
      props: { isMulti: true },
    },
    cities: {
      value: [],
      component: FloatingSelect,
      label: "Store Cities",
      options: [],
      props: { isMulti: true },
    },
    user_segments: {
      value: [],
      component: FloatingSelect,
      label: "Store User Segments",
      options: segments,
      props: {
        isMulti: true,
      },
    },
    tags: {
      value: [],
      component: FloatingSelect,
      label: "Tags of Ordered Assets",
      options: [],
      props: { isMulti: true },
    },
    assets: {
      value: [],
      component: AsyncFloatingSelect,
      // props: {
      //
      // },
      label: "Ordered Assets",
      options: [],
      props: {
        isMulti: true,
        myEntitySuggest: true,
        provisionTempState: true,
        body: { entity: "asset", bundle: ["default"] },
      },
    },
    // brands: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Ordered Brands",
    //   options: [],
    //   props: { isMulti: true },
    // },
    products_visited: {
      value: [],
      component: AsyncFloatingSelect,
      label: "Products Visited",
      options: [],
      props: {
        isMulti: true,
        myEntitySuggest: true,
        body: { entity: "product", bundle: ["default"] },
        provisionTempState: true,
        wrapMultiItems: true,
      },
    },
  })

  useEffect(() => {
    let bodyVars = {}
    Object.keys(params).map((item, k) => {
      if (Array.isArray(params[item].value) && params[item].value.length > 0)
        bodyVars[item] = params[item].value
      else if (!Array.isArray(params[item].value) && params[item].value)
        bodyVars[item] = params[item].value
      setBody(bodyVars)
    })

    return bodyVars
  }, [params])

  const setParam = (param, value) => {
    setParams(prevData => {
      return {
        ...prevData,
        [param]: { ...prevData[param], value: value },
      }
    })
    return { ...params, [param]: { ...params[param], value: value } }
  }

  const getAllData = async () => {
    let response = null
    try {
      response = await getReportStoreSummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value || null,
        args: body,
        date_range: dateRange
          ? `${getFormattedDateForFetch(
            dateRange[0].startDate
          )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
          : "",
        download: true,
      })
      setAllData(response)
      return response
    } catch (e) {
      return toggleNotification({
        content: "Failed to generate CSV",
        error: true,
      })
    }
  }
  const getData = async (pageNumber = 1, refresh = false) => {
    // if(download === true) {
    //   let response = null
    //   try {
    //     response = await getReportStoreSummary({
    //       token: token,
    //       companyId: company.value,
    //       args: body,
    //       date_range: dateRange
    //         ? `${getFormattedDateForFetch(
    //             dateRange[0].startDate
    //           )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
    //         : "",
    //       // offset: pageNumber == 1 ? "0" : `${(pageNumber - 1) * 50}`,
    //       download: true,
    //     })
    //     setAllData(response)
    //     return response;
    //   } catch (e) {
    //     return toggleNotification({ content: "error", error: true })
    //   }
    // }

    setPage(pageNumber)

    if (data[pageNumber]?.length > 0 && refresh === false)
      return data[pageNumber]

    let response = null
    try {
      response = await getReportStoreSummary({
        token: token,
        companyId: company.value,
        brandId: brand?.value || null,
        args: body,
        date_range: dateRange
          ? `${getFormattedDateForFetch(
            dateRange[0].startDate
          )}-${getFormattedDateForFetch(dateRange[0].endDate)}`
          : "",
        offset: pageNumber == 1 ? "0" : `${(pageNumber - 1) * 50}`,
      })
    } catch (e) {
      return toggleNotification({ content: "error", error: true })
    }

    setData(prevData => {
      return {
        ...prevData,
        [pageNumber]: response,
      }
    })
    return response
  }

  useEffect(() => {
    // console.log(data, 'dataroos')
    if (data !== initData) {
      getAllData()
    }
  }, [data])
  useEffect(() => {
    if (allData.length !== 0) getAllData()
  }, [company, brand])
  return (
    <ReportingStoreSummaryContext.Provider
      value={{
        page,
        setPage,
        data,
        setData,
        getData,
        allData,
        setParam,
        params,
        body,
      }}
    >
      {props.children}
    </ReportingStoreSummaryContext.Provider>
  )
}

export default ReportingStoreSummaryProvider
