/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useState } from "react"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import HighlightsWidget from "../../../components/Reporting/Widgets/HighlightsWidget"
import TopPerformersWidget from "../../../components/Reporting/Widgets/TopPerformersWidget"
import MyTopPerformersWidget from "../../../components/Reporting/Widgets/MyTopPerformersWidget"
import RecentOrdersWidget from "../../../components/Reporting/Widgets/RecentOrdersWidget"
import TotalOrdersWidget from "../../../components/Reporting/Widgets/TotalOrdersWidget"
import ReportingContainer from "../../../templates/Dashboard/ReportingContainer"
import { TabTitle, Tabs } from "../../../components/Tabs/TabElements"
import OrderSummary from "../../../templates/Dashboard/Reports/OrderSummary"
import AssetSummary from "../../../templates/Dashboard/Reports/AssetSummary"
import ProductSummary from "../../../templates/Dashboard/Reports/ProductSummary"
import StoreSummary from "../../../templates/Dashboard/Reports/StoreSummary"
import DownloadSummary from "../../../templates/Dashboard/Reports/DownloadSummary"
import InventorySummary from "../../../templates/Dashboard/Reports/InventorySummary"
import CustomerSummary from "../../../templates/Dashboard/Reports/CustomerSummary"
import TrendAnalysis from "../../../templates/Dashboard/Reports/TrendAnalysis"
import TotalVisitsWidget from "../../../components/Reporting/Widgets/TotalVisitsWidget"
import RecentVisitsWidgets from "../../../components/Reporting/Widgets/RecentVisitsWidgets"
import AccessDenied from "../../../components/AccessDenied"
import Webhooks from "../../../templates/Dashboard/Reports/Webhooks"
import { TabSubheading } from "../../../components/Tabs/TabElements"
import ReportingProvider from "../../../context/Reporting/ReportingProvider"

function Reports() {
  const { userType } = useContext(AuthenticationContext)
  const [selectedTab, setSelectedTab] = useState()
  const [topAsset, setTopAsset] = useState()
  const [inventoryStatus, setInventoryStatus] = useState()

  const tabList = [
    {
      name: <TabTitle name={"Overview"} />,
      content: (
        <>
          <TabSubheading text="An executive dashboard overview with Key Performance Indicators." />
          <HighlightsWidget
            setSelectedTab={setSelectedTab}
            setTopAsset={setTopAsset}
            setInventoryStatus={setInventoryStatus}
          />
          <div className="md:flex lg:space-x-4">
            <div className="w-full lg:w-2/3 flex flex-col">
              <TotalVisitsWidget />
            </div>
            <div className="w-full lg:w-1/3 ">
              <RecentVisitsWidgets />
            </div>
          </div>

          <div className="md:flex lg:space-x-4">
            <div className="w-full lg:w-1/3 ">
              <RecentOrdersWidget />
            </div>
            <div className="w-full lg:w-2/3 flex flex-col">
              <TotalOrdersWidget />
            </div>
          </div>

          <div className="md:flex lg:space-x-4">
            <div className="w-full ">
              <TopPerformersWidget />
            </div>
          </div>
          <div className="md:flex lg:space-x-4">
            <div className="w-full ">
              <MyTopPerformersWidget />
            </div>
          </div>
        </>
      ),
    },
    {
      name: <TabTitle name={"Products"} />,
      content: <ProductSummary />,
    },
    {
      name: <TabTitle name={"Assets"} />,
      content: <AssetSummary />,
    },
    {
      name: <TabTitle name={"Stores"} />,
      content: <StoreSummary />,
    },
    {
      name: <TabTitle name={"Customers"} />,
      content: <CustomerSummary />,
    },
    {
      name: <TabTitle name={"Downloads"} />,
      content: <DownloadSummary />,
    },
    {
      name: <TabTitle name={"Orders"} />,
      content: <OrderSummary selectedTab={selectedTab} topAsset={topAsset} />,
    },
    {
      name: <TabTitle name={"Inventory"} />,
      content: (
        <InventorySummary
          selectedTab={selectedTab}
          inventoryStatus={inventoryStatus}
        />
      ),
    },
    {
      name: <TabTitle name={"Trends"} />,
      content: <TrendAnalysis />,
    },
    {
      name: <TabTitle name={"Webhooks"} />,
      content: (
        <>
          <TabSubheading text="Stream your Company's data from the O2O app into one of your own applications." />
          <Webhooks />
        </>
      ),
    },
  ]

  if (!userType.brandManager) return <AccessDenied />
  return (
    <ReportingProvider>
      <ReportingContainer>
        <Tabs
          tabs={tabList}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTopAsset={setTopAsset}
          setInventoryStatus={setInventoryStatus}
        />
      </ReportingContainer>
    </ReportingProvider>
  )
}

export default Reports
