/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import AsyncFloatingSelect from "../Elements/Form/AsyncFloatingSelect"
// import FloatingDateRange from "../Elements/Form/FloatingDateRange"
import ReportingContext from "../../context/Reporting/ReportingContext"
import StateContext from "../../context/state/StateContext"
import DataContext from "../../context/Data/DataContext"
import FloatingCheckbox from "../Elements/Form/FloatingCheckbox"
const TrendAnalysisFilter = ({ setBody }) => {
  const { company, companyUsers, dateRangeFetch, deliveryLocations } =
    useContext(ReportingContext)
  const { token } = useContext(StateContext).state
  const {
    regionsAsOptions,
    myBrandsAsOptions,
    categoriesAsOptions,
    tagsAsOptions,
    myUsersAsOptions,
  } = useContext(DataContext).data

  const [radioState, setRadioState] = useState({
    physical_orders: {
      value: true,
      label: "Physical Orders"
    },
    digital_orders: {
      value: false,
      label: "Digital Orders"
    },
    brand_visits: {
      value: false,
      label: "Brand Visits"
    },
    product_visits: {
      value: false,
      label: "Product Visits"
    },
    asset_visits: {
      value: false,
      label: "Asset Visits"
    }
  })


  useEffect(() => {
    companyUsers.length &&
      setParams(prevData => {
        return {
          ...prevData,
          user: { ...prevData.user, options: companyUsers },
        }
      })
  }, [companyUsers])
  useEffect(() => {
    regionsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          regions: { ...prevData.regions, options: regionsAsOptions },
        }
      })
  }, [regionsAsOptions])
  useEffect(() => {
    myBrandsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          brand: { ...prevData.brand, options: myBrandsAsOptions },
        }
      })
  }, [myBrandsAsOptions])
  useEffect(() => {
    categoriesAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          categories: {
            ...prevData.categories,
            options: categoriesAsOptions,
          },
        }
      })
  }, [categoriesAsOptions])
  useEffect(() => {
    tagsAsOptions.length &&
      setParams(prevData => {
        return {
          ...prevData,
          tags: { ...prevData.tags, options: tagsAsOptions },
        }
      })
  }, [tagsAsOptions])
  useEffect(() => {
    deliveryLocations.length &&
      setParams(prevData => {
        return {
          ...prevData,
          delivery_location: {
            ...prevData.delivery_location,
            options: deliveryLocations,
          },
        }
      })
  }, [deliveryLocations])
  const [params, setParams] = useState({
    regions: {
      value: [],
      component: FloatingSelect,
      label: "Regions",
      options: [],
      props: { isMulti: true },
    },
    // brand: {
    //   value: null,
    //   component: FloatingSelect,
    //   label: "Brand",
    //   options: [],
    //   props: { isMulti: true },
    // },
    categories: {
      value: [],
      component: FloatingSelect,
      label: "Categories",
      props: { isMulti: true },
      options: [],
    },
    tags: {
      value: [],
      component: FloatingSelect,
      label: "Tags",
      options: [],
      props: { isMulti: true },
    },
    // assets: {
    //   value: [],
    //   component: AsyncFloatingSelect,
    //   // props: {
    //   //
    //   // },
    //   label: "Assets",
    //   options: [],
    //   props: {
    //     isMulti: true,
    //     myEntitySuggest: true,
    //     body: { entity: "asset", bundle: ["default"] },
    //   },
    // },
    asset_types: {
      value: [],
      component: FloatingSelect,
      label: "Asset Types",
      options: [
        { value: "digital", label: "Digital" },
        { value: "physical", label: "Physical" },
      ],
      props: { isMulti: false },
    },
    products: {
      value: [],
      component: AsyncFloatingSelect,
      // props: {
      //
      // },
      label: "Products",
      options: [],
      props: {
        isMulti: true,
        myEntitySuggest: true,
        body: { entity: "product", bundle: ["default"] },
      },
    },
    // retailers: {
    //   value: [],
    //   component: AsyncFloatingSelect,
    //   label: "Retailers",
    //   props: {
    //     body: { entity: "retailer", bundle: ["default"] },
    //     isMulti: true,
    //   },
    //   options: [],
    // },
    user: {
      value: [],
      component: FloatingSelect,
      label: "User",
      props: { isMulti: true },
      options: [],
    },
    // user_type: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "User type",
    //   options: [
    //     { value: "all", label: "All" },
    //     { value: "rep", label: "Sales Rep" },
    //     { value: "retailer", label: "Retailer" },
    //   ],
    //   props: { isMulti: false },
    // },
    // time_range: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Time Range",
    //   options: [
    //     { value: "day", label: "Day" },
    //     { value: "week", label: "Week" },
    //     { value: "month", label: "Month" },
    //     { value: "quarter", label: "Quarter" },
    //     { value: "year", label: "Year" },
    //   ],
    //   props: { defaultValue: { value: "week", label: "Week" } },
    // },
    // delivery_location: {
    //   value: [],
    //   component: FloatingSelect,
    //   label: "Delivery location",
    //   options: [],
    //   props: { subtitleSelector: "location" },
    // },
  })

  useEffect(() => {
    let bodyVars = {}
    Object.keys(params).map((item, k) => {
      if (Array.isArray(params[item].value) && params[item].value.length)
        bodyVars[item] = params[item].value
      else if (!Array.isArray(params[item].value) && params[item].value)
        bodyVars[item] = params[item].value
    })

    bodyVars["metric"] = Object.keys(radioState).find(item => radioState[item].value)
    
    console.log("bodyVars", bodyVars)
    setBody(bodyVars)
    // return bodyVars
  }, [params, radioState])

  const setParam = (param, value) => {
    setParams(prevData => {
      return {
        ...prevData,
        [param]: { ...prevData[param], value: value },
      }
    })
    return { ...params, [param]: { ...params[param], value: value } }
  }

  
  return (
    <>
      <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white">
      Choose one metric you want to compare against the benchmark
        <div className="flex space-x-10">
          {Object.keys(radioState).map((item, k) => {
            return (
              <div className="flex items-center">
                <FloatingCheckbox
                  label={radioState[item].label}
                  name={item}
                  value={radioState[item].value}
                  onChange={e => {

                    //make all existing items false
                    let newRadioState = {}
                    Object.keys(radioState).map((item, k) => {
                      newRadioState[item] = { ...radioState[item], value: false }
                    })
                    setRadioState({ ...newRadioState, [item]: { ...radioState[item], value: true } })
                  }}
                  hookForm={false}
                />
              </div>
            )
          }
          )}
        </div>


      </div>
      <div className="border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white">
        <div className="grid grid-cols-4 gap-4">
          {Object.keys(params).map((i, k) => {
            const item = params[i]
            const { label, value, options } = item

            if (item.component)
              return (
                <div
                  css={css`
                    > * {
                      margin-bottom: 0 !important;
                    }
                  `}
                >
                  <item.component
                    label={label}
                    name={i}
                    // value={value}
                    options={options || {}}
                    onChange={e => {
                      if (Array.isArray(e)) {
                        let vals = []
                        e.map((item, k) => {
                          if (i === "regions") vals.push(item.region_code)
                          else vals.push(item.value)
                        })
                        setParam(i, vals)
                      } else setParam(i, e.value)
                    }}
                    {...item.props}
                  />
                </div>
              )
            return null
          })}
        </div>
      </div>
    </>
  )
}

export default TrendAnalysisFilter
