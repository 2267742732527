import React, { useContext, useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js"
import { Line } from "react-chartjs-2"
import moment from "moment"
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext"
import {
  red,
  redLight,
  blue,
  blueLight,
  green,
  greenLight,
  yellow,
  yellowLight,
} from "../../../Styles"
import Skeleton from "../../../Skeleton"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

defaults.font.family = "Jost"
defaults.font.weight = "400"
defaults.color = "#222"
// defaults.legend.display = false



// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: [30, 60, 90, 120, 150, 180],
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Dataset 2",
//       data: [30, 60, 90, 120, 150, 180],
//       borderColor: "rgb(255, 99, 142)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//   ],
// }

export default function TotalOrdersWidgetChart(props) {
  const { data, dateRange } = props
  // const { currentUserData } = useContext(AuthenticationContext).authentication

  // let labels = ["January", "February", "March", "April", "May", "June", "July"]
  // let chartData = []
  function getDays(startDate, endDate) {
    var sd = new Date(startDate)
    var weeks = []

    for (var d = sd; d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
      let nextWeek = moment(new Date(d)).format("DD/MM/YYYY")

      weeks.push(nextWeek)
    }
    return weeks
  }
  function getWeeks(startDate, endDate) {
    var sd = new Date(startDate)
    var weeks = []

    for (var d = sd; d <= new Date(endDate); d.setDate(d.getDate() + 7)) {
      let nextWeek = moment(new Date(d)).format("DD/MM/YYYY")

      weeks.push(nextWeek)
    }
    return weeks
  }
  function getMonths(startDate, endDate) {
    var sd = new Date(startDate)
    var weeks = []
    var week = []
    for (var d = sd; d <= new Date(endDate); d.setMonth(d.getMonth() + 1)) {
      let nextWeek = moment(new Date(d)).format("DD/MM/YYYY")

      weeks.push(nextWeek)
    }
    return weeks
  }

  function getQuarter(startDate, endDate) {
    var sd = new Date(startDate)
    var weeks = []
    var week = []
    for (var d = sd; d <= new Date(endDate); d.setMonth(d.getMonth() + 3)) {
      let nextWeek = moment(new Date(d)).format("DD/MM/YYYY")

      weeks.push(nextWeek)
    }
    return weeks
  }
  function getYears(startDate, endDate) {
    var sd = new Date(startDate)
    var weeks = []

    for (var d = sd; d <= new Date(endDate); d.setDate(d.getDate() + 365)) {
      let nextWeek = moment(new Date(d)).format("DD/MM/YYYY")

      weeks.push(nextWeek)
    }
    return weeks
  }

  // console.log(data, 'data')
  // return null
  // if (true) labels = [...data.results.map(i => i.benchmark_orders)]
  const updateChart = () => {
    let startDate = moment(dateRange.split("-")[0], "DD/MM/YYYY").format(
      "MM/DD/YYYY"
    )
    let endDate = moment(dateRange.split("-")[1], "DD/MM/YYYY").format(
      "MM/DD/YYYY"
    )
    let labels = []

    console.log(dateRange)
    console.log(dateRange)
    console.log(startDate, endDate, "startDate, endDate")

    labels = getDays(startDate, endDate)

    console.log(labels, 'labels')

    let createData = {
      labels,
      datasets: [
        {
          label: `Benchmark`,
          data: [...data.map(i => i.avg)],
          borderColor: "#FF9764",
          backgroundColor: "#FF9764",
          borderDash: [10, 5],
          fill: false,
        },
        {
          label: `Your Company`,
          data: [...data.map(i => i.count)],
          borderColor: "#26919D",
          backgroundColor: "#26919D",
          fill: false,
        },
      ],
    }
    setDatax(createData)

    //find the highest value in the data for avg or count

  }
  const [datax, setDatax] = useState(null)
  // useEffect(updateChart, [])
  // useEffect(updateChart, [data])

  useEffect(() => {

    // // get the highest number value for all data.avg or data.count
    // let highestValue = 0
    // // data.map(i => {
    // //   if(parseInt(i.avg) > highestValue) highestValue = parseInt(i.avg)
    // // })
    // data.map(i => {

    //   if(parseInt(i.count) > highestValue) highestValue = parseInt(i.count)
    // })
    // // console.log(highestValue, 'highestValue')

    // setOptions({
    //   ...options,
    //   scales: {
    //     y: {
    //       max: parseInt(highestValue),
    //     }
    //   }
    // })


    updateChart()

  }, [data])
  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
    },
    tooltips: {
      backgroundColor: "orange",
      bodyAlign: "left",
      bodyFontColor: "red",
      bodySpacing: 2,
      borderColor: "rgba(0,0,0,0)",
      borderWidth: 0,
      // callbacks: {beforeTitle: ƒ, title: ƒ, afterTitle: ƒ, beforeBody: ƒ, beforeLabel: ƒ, …}
      caretPadding: 2,
      caretSize: 5,
      cornerRadius: 6,
      custom: null,
      displayColors: false,
      enabled: true,
      footerAlign: "left",
      footerFontColor: "#fff",
      footerFontStyle: "bold",
      footerMarginTop: 6,
      footerSpacing: 2,
      intersect: true,
      mode: "nearest",
      multiKeyBackground: "#fff",
      position: "average",
      titleAlign: "left",
      titleFontColor: "#fff",
      titleFontStyle: "bold",
      titleMarginBottom: 6,
      titleSpacing: 2,
      xPadding: 6,
      yPadding: 6,
    },
    maintainAspectRatio: false,
    // scales: {
    //   y: { max: 25 }
    // }
  })

  if (!datax)
    return (
      <>
        <Skeleton box />
      </>
    )
  return <div className="h-[500px]"><Line options={options} data={datax} /></div>
}
