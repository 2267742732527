/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Popover, ArrowContainer } from "react-tiny-popover"
import { QuestionMarkCircleIcon } from "@heroicons/react/outline"

const WidgetHead = props => {
  const { title, body } = props
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  return (
    <div className="mb-4 mt-3 flex items-center">
      <h3 className="text-med-18">{title}</h3>
      <Popover
        isOpen={isPopoverOpen}
        // positions={["bottom", "left"]}
        padding={10}
        reposition={true}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={({
          position,
          nudgedLeft,
          nudgedTop,
          childRect,
          popoverRect,
        }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={"#fce3e1"}
            arrowSize={10}
            // arrowStyle={{}}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className=" bg-[#fce3e1] rounded-[3px] text-[#E43D30] text-[11px] px-2 py-2 shadow-[5px_10px_30px_rgba(0,0,0,.1)]">
              {body}
            </div>
          </ArrowContainer>
        )}
      >
        <div
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          css={css`
            &&& {
              * path {
                stroke-width: 1px;
              }
            }
          `}
        >
          <QuestionMarkCircleIcon
            className={`h-4 w-4 ml-1 mt-[1px] transition-all cursor-pointer ${
              isPopoverOpen
                ? `text-[#E43D30]`
                : `text-[rgba(0,0,0,.5)] hover:text-[#E43D30]`
            }`}
          />
        </div>
      </Popover>
    </div>
  )
}

export default WidgetHead
